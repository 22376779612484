export default {
    "_colon_": ":",
    "languages.english": "Inglês",
    "languages.french": "Francês",
    "languages.spanish": "Espanhol",
    "languages.german": "Alemão",
    "languages.hindi": "Hindi",
    "languages.italian": "Italiano",
    "languages.georgian": "Georgiano",
    "languages.portuguese": "Português",
    "languages.russian": "Russo",
    "languages.swedish": "Sueco",
    "languages.turkish": "Turco",
    "languages.ukrainian": "Ucraniano",
    "logoAlt": "Logotipo do braçadeira",
    "navBar.item1.text": "Como funciona?",
    "navBar.item2.text": "Recursos por vir",
    "navBar.item3.text": "Criar Conta",
    "headline": "Bem-vindo ao mundo da queda de braço",
    "headline.part1": "Bem-vindo ao mundo da",
    "headline.part2": "queda de braço",
    "headlineText": "O Armbet é um aplicativo de braçadeira que permite encontrar parceiros para se unir e acompanhar, com muitos outros recursos em breve.",
    "about.title": "Tornando-se um membro",
    "about.text": "Depois de criar um perfil, você ingressará na comunidade de armwrestling. Você poderá encontrar outras pessoas usando nosso mapa, enviá-las por mensagem, encontrar-se, treinar e competir juntos.",
    "features1.title": "Encontrar",
    "features1.text": "Encontre armwrestlers em sua área ou região de interesse",
    "features2.title": "Bate-papo",
    "features2.text": "Enviar mensagem aos usuários para configurar atividades",
    "features3.title": "Treinar e competir",
    "features3.text": "Reúna-se para treinar e competir juntos",
    "comingSoon.title": "Encontrar e criar eventos, juntar-se às fileiras!",
    "comingSoon.text": "Em breve poderás encontrar eventos à tua volta e até criar os teus próprios. Um sistema completo de ranking para todos os lutadores de braço de ferro também está planejado! E nós não vamos parar por aí...",
    "comingSoon.part1": "Encontre e crie",
    "comingSoon.part2": "eventos",
    "comingSoon.part3":", juntar-se às",
    "comingSoon.part4":" fileiras",
    "comingsoon": "Em breve",
    "signUp.title": "Entrar",
    "signUp.text": "Entre no mundo do armwrestling. O esporte mais antigo e acessível. Inscreva-se agora e informaremos quando o aplicativo for lançado.",
    "signUp.part1": "Junte-se",
    "signUp.part2": "agora",
    "signup": "Criar Conta",
    "genders.male": "Masculino",
    "genders.female": "Fêmea",
    "genders.other": "De outros",
    "signUpSuccess.title": "Obrigado por inscrever-se!",
    "signUpSuccess.text": "Você criou a sua conta para a aplicação Armbet que está a chegar em breve. Por favor verifique a sua caixa de entrada para verificar e activar a sua conta. Lembre-se dos seus dados de login e nós entraremos em contato quando o aplicativo for lançado. Vejo-te do outro lado.",
    "password": "Senha",
    "email": "Endereço de e-mail",
    "fullname": "Nome completo",
    "fulladdress": "Endereço completo",
    "gender": "Gênero",
    "createyouraccount": "crie sua conta",
    "changelanguage": "Mude seu idioma",
    "userterms": "Termos e condições do usuário",
    "privacypolicy": "Política de Privacidade",
    "copyrightmessage": "© Copyright 2020 Braçadeira. Todos os direitos reservados.",
    "backtohomepage": "Voltar à página inicial",
    "message": "mensagem",
    "close": "Fechar",
    "accountalreadyexists": "Já existe uma conta com esse endereço de email. Você já deve ter entrado na comunidade.",
    "notFound.title": "404",
    "notFound.text": "Lamentamos, mas não foi possível encontrar a página ...",
    "errorhasoccured": "Lamentamos, mas ocorreu um erro ... Desculpe pela inconveniência.",
    "networkerror": "Lamentamos, mas não foi possível conectar ao servidor ...",
    "emailcouldntsend": "Lamentamos, mas podemos enviar o e-mail. Verifique seu endereço de e-mail e tente novamente.",
    "contactus": "Contate-Nos",
    "nothanks": "Não, obrigado",
    "improvetranslationstitle": "Ajude-nos a melhorar as traduções!",
    "improvetranslationstext": "Entre em contato conosco se você deseja ajudar a melhorar as traduções",

    "accountactivated": "Sua conta foi ativada!",
    "accountalreadyactivated": "Sua conta já foi ativada!",
    "accountactivatedtext": "Você criou a sua conta para a aplicação Armbet que está a chegar em breve. Lembre-se do seu e-mail e senha e nós entraremos em contato quando o aplicativo for lançado. Vejo-te do outro lado.",
    "linknotvalid": "O link expirou",
    "linknotvalidtext": "O link digitado não é válido. Se você deseja ativar sua conta, solicite que um novo e-mail seja enviado para sua caixa de entrada.",

    "privacypolicy.1.text1": "No armbet.com, acessível em www.armbet.com, uma das nossas principais prioridades é a privacidade de nossos visitantes. Este documento da Política de Privacidade contém tipos de informações que são coletadas e registradas pelo armbet.com e como as usamos.",
    "privacypolicy.1.text2": "Se você tiver dúvidas adicionais ou precisar de mais informações sobre nossa Política de Privacidade, não hesite em nos contactar.",
    "privacypolicy.1.text3": "Esta Política de Privacidade aplica-se apenas às nossas atividades online e é válida para os visitantes do nosso site no que diz respeito às informações que eles compartilharam e / ou coletam no armbet.com. Esta política não se aplica a nenhuma informação coletada offline ou por outros canais que não este site.",
    "privacypolicy.2.title": "Consentimento",
    "privacypolicy.2.text1": "Ao usar nosso site, você concorda com nossa Política de Privacidade e concorda com seus termos.",
    "privacypolicy.3.title": "Informações que coletamos",
    "privacypolicy.3.text1": "As informações pessoais solicitadas por você e os motivos pelos quais você as solicita serão esclarecidas no momento em que solicitarmos que você forneça suas informações pessoais.",
    "privacypolicy.3.text2": "Se você entrar em contato conosco diretamente, poderemos receber informações adicionais sobre você, como seu nome, endereço de e-mail, número de telefone, o conteúdo da mensagem e / ou anexos que você pode nos enviar e qualquer outra informação que você escolher fornecer.",
    "privacypolicy.3.text3": "Quando você se registra para uma conta, podemos solicitar suas informações de contato, incluindo itens como nome, nome da empresa, endereço, endereço de e-mail e número de telefone.",
    "privacypolicy.4.title": "Como usamos suas informações",
    "privacypolicy.4.text1": "Usamos as informações que coletamos de várias maneiras, incluindo:",
    "privacypolicy.4.text2": "Fornecer, operar e manter nosso webste",
    "privacypolicy.4.text3": "Melhorar, personalizar e expandir nossos webste",
    "privacypolicy.4.text4": "Entenda e analise como você usa nosso webste",
    "privacypolicy.4.text5": "Desenvolver novos produtos, serviços, recursos e funcionalidades",
    "privacypolicy.4.text6": "Comunique-se com você, diretamente ou através de um de nossos parceiros, inclusive para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao webste e para fins de marketing e promocionais",
    "privacypolicy.4.text7": "Enviar e-mails",
    "privacypolicy.4.text8": "Encontre e evite fraudes",
    "privacypolicy.5.title": "Arquivos de log",
    "privacypolicy.5.text1": "O armbet.com segue um procedimento padrão de uso de arquivos de log. Esses arquivos registram os visitantes quando eles visitam sites. Todas as empresas de hospedagem fazem isso e fazem parte da análise dos serviços de hospedagem. As informações coletadas pelos arquivos de log incluem endereços de protocolo da Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência / saída e, possivelmente, o número de cliques. Estes não estão ligados a nenhuma informação que seja pessoalmente identificável. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas.",
    "privacypolicy.6.title": "Políticas de privacidade de parceiros de publicidade",
    "privacypolicy.6.text1": "Você pode consultar esta lista para encontrar a Política de Privacidade de cada um dos parceiros de publicidade da armbet.com.",
    "privacypolicy.6.text2": "Servidores ou redes de anúncios de terceiros usam tecnologias como cookies, JavaScript ou Web Beacons que são usados em seus respectivos anúncios e links que aparecem no armbet.com, que são enviados diretamente ao navegador dos usuários. Eles recebem automaticamente seu endereço IP quando isso ocorre. Essas tecnologias são usadas para medir a eficácia de suas campanhas publicitárias e / ou para personalizar o conteúdo de publicidade exibido nos sites que você visita.",
    "privacypolicy.6.text3": "Observe que o armbet.com não tem acesso ou controle sobre esses cookies usados por anunciantes de terceiros.",
    "privacypolicy.7.title": "Políticas de Privacidade de Terceiros",
    "privacypolicy.7.text1": "A Política de Privacidade da armbet.com não se aplica a outros anunciantes ou sites. Portanto, recomendamos que você consulte as respectivas políticas de privacidade desses servidores de anúncios de terceiros para obter informações mais detalhadas. Pode incluir suas práticas e instruções sobre como desativar determinadas opções. Você pode encontrar uma lista completa dessas políticas de privacidade e seus links aqui: Links da política de privacidade.",
    "privacypolicy.7.text2": "Você pode optar por desativar os cookies através das opções individuais do seu navegador. Para conhecer informações mais detalhadas sobre o gerenciamento de cookies com navegadores da web específicos, elas podem ser encontradas nos respectivos sites. O que são cookies?",
    "privacypolicy.8.title": "Direitos de privacidade da CCPA (não venda minhas informações pessoais)",
    "privacypolicy.8.text1": "Sob a CCPA, entre outros direitos, os consumidores da Califórnia têm o direito de:",
    "privacypolicy.8.text2": "Solicite que uma empresa que coleta dados pessoais de um consumidor divulgue as categorias e partes específicas de dados pessoais que uma empresa coletou sobre os consumidores.",
    "privacypolicy.8.text3": "Solicite que uma empresa exclua quaisquer dados pessoais sobre o consumidor que uma empresa coletou.",
    "privacypolicy.8.text4": "Solicite que uma empresa que venda dados pessoais de um consumidor não venda os dados pessoais do consumidor.",
    "privacypolicy.8.text5": "Se você fizer uma solicitação, temos um mês para responder a você. Se você deseja exercer algum desses direitos, entre em contato conosco.",
    "privacypolicy.9.title": "Direitos de proteção de dados GDPR",
    "privacypolicy.9.text1": "Gostaríamos de garantir que você esteja totalmente ciente de todos os seus direitos de proteção de dados. Todo usuário tem direito ao seguinte:",
    "privacypolicy.9.text2": "O direito de acessar - Você tem o direito de solicitar cópias de seus dados pessoais. Podemos cobrar uma pequena taxa por esse serviço.",
    "privacypolicy.9.text3": "O direito de retificação - Você tem o direito de solicitar que corrijamos qualquer informação que considere imprecisa. Você também tem o direito de solicitar que completemos as informações que você acha que estão incompletas.",
    "privacypolicy.9.text4": "O direito de apagar - Você tem o direito de solicitar que apagemos seus dados pessoais, sob certas condições.",
    "privacypolicy.9.text5": "O direito de restringir o processamento - Você tem o direito de solicitar que restrinamos o processamento de seus dados pessoais, sob certas condições.",
    "privacypolicy.9.text6": "O direito de se opor ao processamento - Você tem o direito de se opor ao nosso processamento de seus dados pessoais, sob certas condições.",
    "privacypolicy.9.text7": "O direito à portabilidade de dados - Você tem o direito de solicitar a transferência dos dados que coletamos para outra organização, ou diretamente para você, sob certas condições.",
    "privacypolicy.9.text8": "Se você fizer uma solicitação, temos um mês para responder a você. Se você deseja exercer algum desses direitos, entre em contato conosco.",
    "privacypolicy.10.title": "Informações para Crianças",
    "privacypolicy.10.text1": "Outra parte de nossa prioridade é adicionar proteção para crianças enquanto estiver usando a Internet. Incentivamos os pais e responsáveis a observar, participar e / ou monitorar e orientar suas atividades on-line.",
    "privacypolicy.10.text2": "O armbet.com não coleta intencionalmente nenhuma informação pessoal identificável de crianças menores de 13 anos. Se você acha que seu filho forneceu esse tipo de informação em nosso site, recomendamos que você entre em contato imediatamente e faremos o possível para remova imediatamente essas informações de nossos registros.",
    "user.terms.1.text1": "Estes termos e condições descrevem as regras e regulamentos para o uso do site da Armbet, Inc.",
    "user.terms.1.text2": "Armbet, Inc está localizado em:",
    "user.terms.1.text3": "Leslie St 5863 Unit 610 North York <br /> M2H 1J8 - Ontario, Canadá",
    "user.terms.1.text4": "Ao acessar este site, assumimos que você aceita estes termos e condições na íntegra. Não continue usando o site da Armbet, Inc se você não aceitar todos os termos e condições estabelecidos nesta página.",
    "user.terms.1.text5": "A terminologia a seguir se aplica a estes Termos e Condições, Declaração de Privacidade e Aviso de Isenção de Responsabilidade e a todos ou quaisquer Acordos: 'Cliente', 'Você' e 'Seu' refere-se a você, a pessoa que acessa este site e aceita os termos e condições da Empresa. 'Empresa', 'Nós mesmos', 'Nós', 'Nosso' e 'Nós' se refere à nossa Empresa. 'Parte', 'Partes' ou 'Nós' refere-se ao Cliente e a nós mesmos, ou ao Cliente ou a nós mesmos. Todos os termos se referem à oferta, aceitação e consideração do pagamento necessário para realizar o processo de nossa assistência ao Cliente da maneira mais apropriada, seja por reuniões formais de duração fixa ou por qualquer outro meio, com o objetivo expresso de atender às Necessidades do cliente em relação ao fornecimento dos serviços / produtos declarados da Companhia, de acordo com e sujeitos à lei vigente no Canadá. Qualquer uso da terminologia acima ou de outras palavras no singular, plural, letras maiúsculas e / ou ele / ela ou elas, são consideradas intercambiáveis e, portanto, referentes à mesma.",
    "user.terms.2.title": "Hiperligação para o nosso conteúdo",
    "user.terms.2.text1": "As seguintes organizações podem se vincular ao nosso site sem a aprovação prévia por escrito:",
    "user.terms.2.text2": "Agências governamentais;",
    "user.terms.2.text3": "Motores de busca;",
    "user.terms.2.text4": "Organizações de notícias;",
    "user.terms.2.text5": "Os distribuidores de diretório online, quando nos listarem no diretório, podem criar um link para nosso site da mesma maneira que fazem o link para os sites de outras empresas listadas;",
    "user.terms.2.text6": "Empresas credenciadas em todo o sistema, exceto a solicitação de organizações sem fins lucrativos, shoppings de caridade e grupos de angariação de fundos de caridade que não possam vincular-se ao nosso site.",
    "user.terms.2.text7": "Essas organizações podem criar links para nossa página inicial, publicações ou outras informações do site, desde que o link: (a) não seja de forma alguma enganoso; (b) não implica falsamente patrocínio, endosso ou aprovação da parte vinculativa e de seus produtos ou serviços; e (c) se encaixa no contexto do site da parte vinculativa.",
    "user.terms.2.text8": "Podemos considerar e aprovar, a nosso critério, outras solicitações de links dos seguintes tipos de organizações:",
    "user.terms.2.text9": "fontes de informações de consumidores e / ou comerciais conhecidas como Câmaras de Comércio, American Automobile Association, AARP e Consumers Union;",
    "user.terms.2.text10": "sites da comunidade dot.com;",
    "user.terms.2.text11": "associações ou outros grupos representando instituições de caridade, incluindo sites de doações,",
    "user.terms.2.text12": "distribuidores de diretórios online;",
    "user.terms.2.text13": "portais de internet;",
    "user.terms.2.text14": "empresas de contabilidade, direito e consultoria cujos principais clientes são empresas; e",
    "user.terms.2.text15": "instituições educacionais e associações comerciais.",
    "user.terms.2.text16": "Aprovaremos as solicitações de link dessas organizações se determinarmos que: (a) o link não refletiria desfavoravelmente sobre nós ou sobre nossas empresas credenciadas (por exemplo, associações comerciais ou outras organizações que representam tipos de negócios inerentemente suspeitos, como trabalho em oportunidades de casa, não será permitido vincular); (b) a organização não possui um registro insatisfatório conosco; (c) o benefício para nós da visibilidade associada ao hiperlink supera a ausência da Armbet, Inc; e (d) quando o link estiver no contexto de informações gerais sobre recursos ou for consistente com o conteúdo editorial de um boletim informativo ou produto similar, promovendo a missão da organização.",
    "user.terms.2.text17": "Essas organizações podem criar links para nossa página inicial, publicações ou outras informações do site, desde que o link: (a) não seja de forma alguma enganoso; (b) não implica falsamente patrocínio, endosso ou aprovação da parte vinculativa e de seus produtos ou serviços; e (c) se encaixa no contexto do site da parte vinculativa.",
    "user.terms.2.text18": "Se você estiver entre as organizações listadas no parágrafo 2 acima e estiver interessado em criar um link para nosso site, deverá notificar-nos enviando um e-mail para",
    "user.terms.2.text19": "Inclua seu nome, nome da organização, informações de contato (como número de telefone e / ou endereço de e-mail), bem como o URL do seu site, uma lista de quaisquer URLs dos quais você pretende vincular ao nosso site, e uma lista dos URLs em nosso site aos quais você deseja vincular. Aguarde 2-3 semanas para uma resposta.",
    "user.terms.2.text20": "As organizações aprovadas podem criar um link para o nosso site da seguinte maneira:",
    "user.terms.2.text21": "Pelo uso de nosso nome corporativo; ou",
    "user.terms.2.text22": "Pelo uso do localizador uniforme de recursos (endereço da Web) ao qual está sendo vinculado; ou",
    "user.terms.2.text23": "O uso de qualquer outra descrição de nosso site ou material vinculado a isso faz sentido dentro do contexto e formato do conteúdo no site da parte vinculativa.",
    "user.terms.2.text24": "Não será permitido o uso do logotipo da Armbet, Inc ou outras obras de arte para vincular um contrato de licença de marca ausente.",
    "user.terms.3.title": "Remoção de links do nosso site",
    "user.terms.3.text1": "Se você encontrar algum link em nosso site ou qualquer site vinculado que seja questionável por qualquer motivo, entre em contato conosco. Consideraremos solicitações para remover links, mas não temos obrigação de fazê-lo ou de responder diretamente a você.",
    "user.terms.3.text2": "Embora nos esforcemos para garantir que as informações neste site estejam corretas, não garantimos sua integridade ou precisão; nem nos comprometemos a garantir que o site permaneça disponível ou que o material no site seja atualizado.",
    "user.terms.4.title": "Responsabilidade pelo Conteúdo",
    "user.terms.4.text1": "Não nos responsabilizamos por qualquer conteúdo que apareça no seu site. Você concorda em indenizar e defender-nos de todas as reivindicações decorrentes ou baseadas no seu site. Nenhum link pode aparecer em qualquer página do seu site ou em qualquer contexto que contenha conteúdo ou materiais que possam ser interpretados como difamatórios, obscenos ou criminais, ou que viole, viole ou defenda a violação ou outra violação de qualquer direitos de terceiros.",
    "user.terms.5.title": "aviso Legal",
    "user.terms.5.text1": "Na extensão máxima permitida pela lei aplicável, excluímos todas as representações, garantias e condições relacionadas ao nosso site e ao uso deste site (incluindo, sem limitação, quaisquer garantias implícitas por lei em relação à qualidade satisfatória, adequação ao objetivo e / ou uso de cuidados e habilidades razoáveis). Nada neste aviso legal irá:",
    "user.terms.5.text2": "limitar ou excluir nossa ou sua responsabilidade por morte ou danos pessoais resultantes de negligência;",
    "user.terms.5.text3": "limitar ou excluir nossa ou sua responsabilidade por fraude ou deturpação fraudulenta;",
    "user.terms.5.text4": "limitar qualquer um de nossos ou seus passivos de qualquer maneira que não seja permitida pela lei aplicável; ou",
    "user.terms.5.text5": "excluir qualquer um de nossos ou seus passivos que não possam ser excluídos pela lei aplicável.",
    "user.terms.5.text6": "As limitações e exclusões de responsabilidade estabelecidas nesta Seção e em outras partes deste aviso: (a) estão sujeitas ao parágrafo anterior; e (b) governar todas as responsabilidades decorrentes da renúncia ou em relação ao objeto da renúncia, incluindo responsabilidades decorrentes de contrato, delito (incluindo negligência) e por violação do dever estatutário.",
    "user.terms.5.text7": "Na medida em que o site e as informações e serviços do site sejam fornecidos gratuitamente, não seremos responsáveis por qualquer perda ou dano de qualquer natureza."
}