export default {
    "_colon_": ":",
    "languages.english": "Engelsk",
    "languages.french": "Franska",
    "languages.spanish": "Spanska",
    "languages.german": "Tysk",
    "languages.hindi": "Hindi",
    "languages.italian": "Italiensk",
    "languages.georgian": "Georgiansk",
    "languages.portuguese": "Portugisiska",
    "languages.russian": "Ryska",
    "languages.swedish": "Svenska",
    "languages.turkish": "Turkish",
    "languages.ukrainian": "Ukrainska",
    "logoAlt": "Armbet logotyp",
    "navBar.item1.text": "Hur fungerar det?",
    "navBar.item2.text": "Kommande funktioner",
    "navBar.item3.text": "Skapa konto",
    "headline": "Välkommen till armbrytningens värld",
    "headline.part1": "Välkommen till världen av",
    "headline.part2": "armbrytning",
    "headlineText": "Armbet är en armwrestling-app som gör det möjligt för dig att hitta partners att slå sig ihop och greppa upp, med många fler funktioner som kommer snart.",
    "about.title": "Bli medlem",
    "about.text": "När du skapar en profil kommer du att gå med i armwrestling community. Du kommer att kunna hitta andra med vår karta, meddela dem, träffa och träna och tävla tillsammans.",
    "features1.title": "Hitta",
    "features1.text": "Hitta armbrytare i ditt område eller intresseområde",
    "features2.title": "Chatt",
    "features2.text": "Meddela användare att ställa in aktiviteter",
    "features3.title": "Träna och tävla",
    "features3.text": "Möt upp för att träna och tävla tillsammans",
    "comingSoon.title": "Hitta och skapa händelser, gå med i rankningarna!",
    "comingSoon.text": "Snart kommer du att kunna hitta evenemang omkring dig och till och med skapa dina egna. Ett komplett rankningssystem för alla armbrytare planeras också! Och vi kommer inte att stanna där ...",
    "comingSoon.part1": "Hitta och skapa",
    "comingSoon.part2": "evenemang",
    "comingSoon.part3":", gå med i",
    "comingSoon.part4":" rankningarna",
    "comingsoon": "Kommer snart",
    "signUp.title": "Gå med nu",
    "signUp.text": "Gå in i armwrestlingens värld. Den mest forntida och tillgängliga sporten. Registrera dig nu så meddelar vi dig när appen har släppts.",
    "signUp.part1": "Ansluta sig",
    "signUp.part2": "nu",
    "signup": "Skapa konto",
    "genders.male": "Manlig",
    "genders.female": "Kvinna",
    "genders.other": "Övrig",
    "signUpSuccess.title": "Tack för din registrering!",
    "signUpSuccess.text": "Du har skapat ditt konto för Armbet-appen som kommer snart. Kontrollera din inkorg för att verifiera och aktivera ditt konto. Kom ihåg dina inloggningsuppgifter och vi kommer att kontakta dig när appen har släppts. Se dig på andra sidan.",
    "password": "Lösenord",
    "email": "E-postadress",
    "fullname": "Fullständiga namn",
    "fulladdress": "Hela adressen",
    "gender": "Kön",
    "createyouraccount": "Skapa ditt konto",
    "changelanguage": "Ändra ditt språk",
    "userterms": "Användarvillkor",
    "privacypolicy": "Integritetspolicy",
    "copyrightmessage": "© Copyright 2020 Armbet. Alla rättigheter förbehållna.",
    "backtohomepage": "tillbaka till startsidan",
    "message": "Meddelande",
    "close": "Stänga",
    "accountalreadyexists": "Ett konto med den e-postadressen finns redan. Du kanske redan har gått med i gemenskapen.",
    "notFound.title": "404",
    "notFound.text": "Vi beklagar men den sidan kunde inte hittas ...",
    "errorhasoccured": "Vi är ledsna men ett fel inträffade ... Ledsen för besväret.",
    "networkerror": "Vi beklagar men vi kunde inte ansluta till servern ...",
    "emailcouldntsend": "Vi är ledsna men vi kunde skicka e-postmeddelandet. Kontrollera din e-postadress och försök igen.",
    "contactus": "Kontakta oss",
    "nothanks": "Nej tack",
    "improvetranslationstitle": "Hjälp oss att förbättra översättningarna!",
    "improvetranslationstext": "Kontakta oss om du vill hjälpa till att förbättra översättningarna",

    "accountactivated": "konto har aktiverats!",
    "accountalreadyactivated": "Ditt konto har redan aktiverats!",
    "accountactivatedtext": "Du har skapat ditt konto för Armbet-appen som kommer snart. Kom ihåg ditt e-postmeddelande och lösenord så kommer vi att kontakta dig när appen har släppts. Vi ses på andra sidan.",
    "linknotvalid": "Länken har gått ut",
    "linknotvalidtext": "Länken du angav är inte giltig, om du vill aktivera ditt konto, be om att ett nytt e-postmeddelande skickas till din inkorg.",

    "privacypolicy.1.text1": "På armbet.com, tillgänglig från www.armbet.com, är en av våra viktigaste prioriteringar våra besökares integritet. Detta dokument för sekretesspolicy innehåller typer av information som samlas in och registreras av armbet.com och hur vi använder den.",
    "privacypolicy.1.text2": "Om du har ytterligare frågor eller behöver mer information om vår integritetspolicy, tveka inte att kontakta oss.",
    "privacypolicy.1.text3": "Denna sekretesspolicy gäller endast för våra onlineaktiviteter och är giltig för besökare på vår webbplats när det gäller informationen som de delade och / eller samlar in på armbet.com. Denna policy är inte tillämplig på någon information som samlas in offline eller via andra kanaler än denna webbplats.",
    "privacypolicy.2.title": "Samtycke",
    "privacypolicy.2.text1": "Genom att använda vår webbplats godkänner du härmed vår integritetspolicy och samtycker till dess villkor.",
    "privacypolicy.3.title": "Information vi samlar in",
    "privacypolicy.3.text1": "Den personliga information som du blir ombedd att tillhandahålla, och orsakerna till att du blir ombedd att tillhandahålla den, kommer att klargöras för dig vid den punkt vi ber dig att lämna din personliga information.",
    "privacypolicy.3.text2": "Om du kontaktar oss direkt kan vi få ytterligare information om dig, till exempel ditt namn, e-postadress, telefonnummer, meddelandets innehåll och / eller bilagor du kan skicka till oss, och all annan information du kan välja att ge.",
    "privacypolicy.3.text3": "När du registrerar dig för ett konto kan vi be om din kontaktinformation, inklusive saker som namn, företagsnamn, adress, e-postadress och telefonnummer.",
    "privacypolicy.4.title": "Hur vi använder din information",
    "privacypolicy.4.text1": "Vi använder informationen vi samlar in på olika sätt, inklusive för att:",
    "privacypolicy.4.text2": "Tillhandahålla, driva och underhålla vår webste",
    "privacypolicy.4.text3": "Förbättra, anpassa och utöka vår webb",
    "privacypolicy.4.text4": "Förstå och analysera hur du använder vår webste",
    "privacypolicy.4.text5": "Utveckla nya produkter, tjänster, funktioner och funktionalitet",
    "privacypolicy.4.text6": "Kommunicera med dig, antingen direkt eller genom en av våra partners, inklusive för kundservice, för att ge dig uppdateringar och annan information som hänför sig till webste, och för marknadsförings- och marknadsföringsändamål",
    "privacypolicy.4.text7": "Skicka e-postmeddelanden",
    "privacypolicy.4.text8": "Hitta och förhindra bedrägeri",
    "privacypolicy.5.title": "Loggfiler",
    "privacypolicy.5.text1": "armbet.com följer en standardprocedur för att använda loggfiler. Dessa filer loggar besökare när de besöker webbplatser. Alla värdföretag gör detta och en del av webbhotellens analys. Informationen som samlas in av loggfiler inkluderar IP-adresser (internetprotokoll), webbläsartyp, Internet-leverantör (ISP), datum och tidstämpel, referens- / utgångssidor och eventuellt antalet klick. Dessa är inte länkade till någon information som är personligt identifierbar. Syftet med informationen är att analysera trender, administrera webbplatsen, spåra användarnas rörelse på webbplatsen och samla in demografisk information.",
    "privacypolicy.6.title": "Annonspartners integritetspolicy",
    "privacypolicy.6.text1": "Du kan konsultera den här listan för att hitta sekretesspolicyn för var och en av reklampartnerna på armbet.com.",
    "privacypolicy.6.text2": "Tredjepartsannonsserver eller annonsnätverk använder teknologier som kakor, JavaScript eller webb Beacons som används i deras respektive annonser och länkar som visas på armbet.com, som skickas direkt till användarens webbläsare. De får automatiskt din IP-adress när detta inträffar. Denna teknik används för att mäta effektiviteten i deras reklamkampanjer och / eller för att anpassa det annonseringsinnehåll som du ser på webbplatser som du besöker.",
    "privacypolicy.6.text3": "Observera att armbet.com inte har tillgång till eller kontroll över dessa cookies som används av tredjepartsannonsörer.",
    "privacypolicy.7.title": "Tredjeparts sekretesspolicy",
    "privacypolicy.7.text1": "armbet.coms sekretesspolicy gäller inte andra annonsörer eller webbplatser. Därför rekommenderar vi att du konsulterar respektive sekretesspolicy för dessa tredjepartsannonsserver för mer detaljerad information. Det kan innehålla deras praxis och instruktioner om hur man väljer bort vissa alternativ. Du kan hitta en komplett lista över dessa sekretesspolicyer och deras länkar här: Sekretesspolicy Länkar.",
    "privacypolicy.7.text2": "Du kan välja att inaktivera cookies genom dina individuella webbläsaralternativ. Om du vill veta mer detaljerad information om cookiehantering med specifika webbläsare kan du hitta den på webbläsarnas respektive webbplatser. Vad är kakor?",
    "privacypolicy.8.title": "CCPA Sekretessrättigheter (Sälj inte min personliga information)",
    "privacypolicy.8.text1": "Enligt CCPA, bland andra rättigheter, har konsumenter i Kalifornien rätt att:",
    "privacypolicy.8.text2": "Begär att ett företag som samlar in konsumentens personuppgifter avslöjar de kategorier och specifika personuppgifter som ett företag har samlat in om konsumenterna.",
    "privacypolicy.8.text3": "Begär att ett företag tar bort alla personuppgifter om konsumenten som ett företag har samlat in.",
    "privacypolicy.8.text4": "Begär att ett företag som säljer en konsument personuppgifter, inte säljer konsumentens personuppgifter.",
    "privacypolicy.8.text5": "Om du gör en begäran har vi en månad att svara på dig. Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss.",
    "privacypolicy.9.title": "GDPR Dataskyddsrättigheter",
    "privacypolicy.9.text1": "Vi vill se till att du är helt medveten om alla dina dataskyddsrättigheter. Varje användare har rätt till följande:",
    "privacypolicy.9.text2": "Rätten till tillgång - Du har rätt att begära kopior av dina personuppgifter. Vi kan ta ut en liten avgift för den här tjänsten.",
    "privacypolicy.9.text3": "Rätten till rättelse - Du har rätt att begära att vi korrigerar all information som du anser är felaktig. Du har också rätt att begära att vi fyller i den information som du anser är ofullständig.",
    "privacypolicy.9.text4": "Rätten att radera - Du har rätt att begära att vi raderar dina personuppgifter under vissa villkor.",
    "privacypolicy.9.text5": "Rätten att begränsa behandlingen - Du har rätt att begära att vi begränsar behandlingen av dina personuppgifter under vissa villkor.",
    "privacypolicy.9.text6": "Rätten att invända mot behandling - Du har rätt att invända mot vår behandling av dina personuppgifter, under vissa villkor.",
    "privacypolicy.9.text7": "Rätten till dataportabilitet - Du har rätt att begära att vi överför data som vi samlat in till en annan organisation, eller direkt till dig, under vissa villkor.",
    "privacypolicy.9.text8": "Om du gör en begäran har vi en månad att svara på dig. Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss.",
    "privacypolicy.10.title": "Barninformation",
    "privacypolicy.10.text1": "En annan del av vår prioritering är att lägga till skydd för barn när du använder internet. Vi uppmuntrar föräldrar och vårdnadshavare att observera, delta i och / eller övervaka och vägleda deras onlineaktivitet.",
    "privacypolicy.10.text2": "armbet.com samlar inte medvetet in någon personlig identifierbar information från barn under 13 år. Om du tror att ditt barn tillhandahöll denna typ av information på vår webbplats, uppmanar vi dig starkt att kontakta oss omedelbart och vi kommer att göra vårt bästa för att omedelbart ta bort sådan information från våra poster.",
    "user.terms.1.text1": "Dessa villkor beskriver regler och regler för användning av Armbet, Inc: s webbplats.",
    "user.terms.1.text2": "Armbet, Inc ligger på:",
    "user.terms.1.text3": "Leslie St 5863 Unit 610 North York <br /> M2H 1J8 - Ontario, Kanada",
    "user.terms.1.text4": "Genom att komma åt denna webbplats antar vi att du accepterar dessa villkor i sin helhet. Fortsätt inte använda Armbet, Incs webbplats om du inte accepterar alla villkor som anges på denna sida.",
    "user.terms.1.text5": "Följande terminologi gäller för dessa villkor, integritetspolicy och meddelanden om ansvarsfriskrivning och alla eller alla överenskommelser: 'Kund', 'Du' och 'Din' hänvisar till dig, personen som går in på denna webbplats och accepterar företagets villkor. 'Företaget', 'oss själv', 'vi', 'vårt' och 'oss', hänvisar till vårt företag. 'Party', 'Parties' eller 'Us', avser både klienten och oss själva, eller antingen klienten eller oss själva. Alla villkor avser erbjudande, acceptans och övervägande av betalning som är nödvändig för att genomföra processen för vår hjälp till klienten på det mest lämpliga sättet, vare sig det är formella möten med en fast tid, eller på något annat sätt, för att uttryckligen se till att möta Kundens behov avseende tillhandahållande av företagets angivna tjänster / produkter, i enlighet med och under förutsättning av gällande lag i Kanada. All användning av ovanstående terminologi eller andra ord i singulär, flertal, kapitalisering och / eller han / hon eller de, tas som utbytbara och därför hänvisar till samma.",
    "user.terms.2.title": "Hyperlänkning till vårt innehåll",
    "user.terms.2.text1": "Följande organisationer kan länka till vår webbplats utan skriftligt godkännande:",
    "user.terms.2.text2": "Statliga myndigheter;",
    "user.terms.2.text3": "Sökmotorer;",
    "user.terms.2.text4": "Nyhetsorganisationer;",
    "user.terms.2.text5": "Distributörer av kataloger online när de listar oss i katalogen kan länka till vår webbplats på samma sätt som de hyperlänkar till webbplatser för andra listade företag;",
    "user.terms.2.text6": "Systemomfattande ackrediterade företag förutom att söka ideella organisationer, välgörenhetsgallerior och välgörenhetsorganisationer som kanske inte länkar till vår webbplats.",
    "user.terms.2.text7": "Dessa organisationer kan länka till vår hemsida, till publikationer eller till annan webbplatsinformation så länge länken: (a) inte på något sätt är vilseledande; (b) innebär inte falskt sponsring, godkännande eller godkännande av den kopplande parten och dess produkter eller tjänster; och (c) passar inom ramen för den länkande partens webbplats.",
    "user.terms.2.text8": "Vi kan överväga och godkänna efter eget gottfinnande andra länkförfrågningar från följande typer av organisationer:",
    "user.terms.2.text9": "allmänt kända konsument- och / eller företagsinformationskällor såsom Chambers of Commerce, American Automobile Association, AARP och Consumers Union;",
    "user.terms.2.text10": "dot.com community-webbplatser;",
    "user.terms.2.text11": "föreningar eller andra grupper som representerar välgörenhetsorganisationer, inklusive välgörenhetssidor,",
    "user.terms.2.text12": "distributörer av online-kataloger;",
    "user.terms.2.text13": "internetportaler;",
    "user.terms.2.text14": "bokförings-, advokat- och konsultföretag vars huvudkunder är företag; och",
    "user.terms.2.text15": "utbildningsinstitutioner och branschorganisationer.",
    "user.terms.2.text16": "Vi kommer att godkänna länkförfrågningar från dessa organisationer om vi fastställer att: (a) länken inte skulle reflektera ogynnsamt på oss eller våra ackrediterade företag (till exempel branschföreningar eller andra organisationer som representerar i sig misstänkta typer av företag, t.ex. hemmöjligheter, får inte tillåtas länka); (b) organisationen inte har en otillfredsställande post med oss; (c) nyttan för oss från synligheten förknippad med hyperlänken överväger frånvaron av Armbet, Inc; och (d) där länken är i samband med allmän resursinformation eller på annat sätt överensstämmer med redaktionellt innehåll i ett nyhetsbrev eller liknande produkt som främjar organisationens uppdrag.",
    "user.terms.2.text17": "Dessa organisationer kan länka till vår hemsida, till publikationer eller till annan webbplatsinformation så länge länken: (a) inte på något sätt är vilseledande; (b) innebär inte falskt sponsring, godkännande eller godkännande av den kopplande parten och dess produkter eller tjänster; och (c) passar inom ramen för den länkande partens webbplats.",
    "user.terms.2.text18": "Om du hör till de organisationer som anges i punkt 2 ovan och är intresserade av att länka till vår webbplats måste du meddela oss genom att skicka ett e-postmeddelande till",
    "user.terms.2.text19": "Vänligen ange ditt namn, ditt organisationsnamn, kontaktinformation (till exempel ett telefonnummer och / eller e-postadress) såväl som webbadressen på din webbplats, en lista med alla webbadresser som du tänker länka till vår webbplats, och en lista över webbadresserna på vår webbplats som du vill länka till. Låt 2-3 veckor få svar.",
    "user.terms.2.text20": "Godkända organisationer kan hyperlänka till vår webbplats på följande sätt:",
    "user.terms.2.text21": "Genom att använda vårt företagsnamn; eller",
    "user.terms.2.text22": "Genom att använda den enhetliga resurslokalen (webbadress) som är kopplad till; eller",
    "user.terms.2.text23": "Genom att använda någon annan beskrivning av vår webbplats eller material som är länkad till är det vettigt inom ramen och formatet på innehållet på den länkande partens webbplats.",
    "user.terms.2.text24": "Ingen användning av Armbet, Incs logotyp eller andra konstverk kommer att tillåtas för att länka från varumärkeslicensavtalet.",
    "user.terms.3.title": "Borttagning av länkar från vår webbplats",
    "user.terms.3.text1": "Om du hittar någon länk på vår webbplats eller någon länkad webbplats av någon anledning kan orsaka, kan du kontakta oss om detta. Vi kommer att överväga förfrågningar om att ta bort länkar men har ingen skyldighet att göra det eller att svara direkt på dig.",
    "user.terms.3.text2": "Medan vi strävar efter att se till att informationen på denna webbplats är korrekt, garanterar vi inte dess fullständighet eller noggrannhet; Vi förbinder oss inte heller att se till att webbplatsen förblir tillgänglig eller att materialet på webbplatsen hålls uppdaterat.",
    "user.terms.4.title": "Innehållsansvar",
    "user.terms.4.text1": "Vi har inget ansvar eller ansvar för innehåll som visas på din webbplats. Du samtycker till att skydda och försvara oss mot alla fordringar som uppstår på eller baserat på din webbplats. Inga länk (er) får visas på någon sida på din webbplats eller inom något sammanhang som innehåller innehåll eller material som kan tolkas som skadligt, obscen eller kriminellt, eller som kränker, på annat sätt kränker eller förespråkar överträdelsen eller annan kränkning av någon tredje parts rättigheter.",
    "user.terms.5.title": "varning",
    "user.terms.5.text1": "I den maximala utsträckning som tillåts enligt tillämplig lag, utesluter vi alla föreställningar, garantier och villkor som rör vår webbplats och användningen av denna webbplats (inklusive, utan begränsning, alla garantier som är implicerade av lagen med avseende på tillfredsställande kvalitet, lämplighet för syfte och / eller användningen av rimlig vård och skicklighet). Ingenting i denna ansvarsfriskrivning kommer att:",
    "user.terms.5.text2": "begränsa eller utesluta vårt eller ditt ansvar för dödsfall eller personskada till följd av vårdslöshet;",
    "user.terms.5.text3": "begränsa eller utesluta vårt eller ditt ansvar för bedrägeri eller bedräglig felrepresentation;",
    "user.terms.5.text4": "begränsa någon av våra eller dina skulder på något sätt som inte är tillåtet enligt tillämplig lag; eller",
    "user.terms.5.text5": "utesluter någon av våra eller dina skulder som inte kan uteslutas enligt tillämplig lag.",
    "user.terms.5.text6": "De begränsningar och undantag av ansvar som anges i detta avsnitt och på annat håll i denna ansvarsfriskrivning: (a) omfattas av föregående stycke; och (b) reglera alla skulder som uppstår enligt ansvarsfriskrivningen eller i förhållande till ämnet för denna ansvarsfriskrivning, inklusive skulder som uppkommer i kontrakt, i skadestånd (inklusive försumlighet) och för brott mot lagstadgad tull.",
    "user.terms.5.text7": "I den utsträckning webbplatsen och informationen och tjänsterna på webbplatsen tillhandahålls kostnadsfritt, är vi inte ansvariga för förlust eller skada av någon art."
}