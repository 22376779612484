export default {
    "_colon_": ":",
    "languages.english": "Inglese",
    "languages.french": "Francese",
    "languages.spanish": "Spagnolo",
    "languages.german": "Tedesco",
    "languages.hindi": "Hindi",
    "languages.italian": "Italiano",
    "languages.georgian": "Georgiano",
    "languages.portuguese": "Portoghese",
    "languages.russian": "Russo",
    "languages.swedish": "Svedese",
    "languages.turkish": "Turco",
    "languages.ukrainian": "Ucraino",
    "logoAlt": "Logo Armbet",
    "navBar.item1.text": "Come funziona?",
    "navBar.item2.text": "Funzionalità a venire",
    "navBar.item3.text": "Creare un profilo",
    "headline": "Benvenuti nel mondo del braccio di ferro",
    "headline.part1": "Benvenuti nel mondo di",
    "headline.part2": "braccio di ferro",
    "headlineText": "Armbet è un'app di armwrestling che ti consente di trovare partner per fare squadra e afferrare, con molte altre funzionalità in arrivo.",
    "about.title": "Diventare membro",
    "about.text": "Dopo aver creato un profilo, ti unirai alla comunità del braccio di ferro. Sarai in grado di trovare altri usando la nostra mappa, inviargli messaggi, incontrarti, allenarti e competere insieme.",
    "features1.title": "Trova",
    "features1.text": "Trova armwrestlers nella tua zona o regione di interesse",
    "features2.title": "Chiacchierare",
    "features2.text": "Invia messaggi agli utenti per impostare le attività",
    "features3.title": "Allenati e competi",
    "features3.text": "Incontrarsi per allenarsi e competere insieme",
    "comingSoon.title": "Trova e crea eventi, unisciti ai ranghi!",
    "comingSoon.text": "Presto sarai in grado di trovare gli eventi che ti circondano e persino di crearne di tuoi. È previsto anche un sistema di classificazione completo per tutti i lottatori a braccio di ferro! E non ci fermeremo qui...",
    "comingSoon.part1": "Trova e crea",
    "comingSoon.part2": "eventi",
    "comingSoon.part3":", unisciti al",
    "comingSoon.part4":" ranghi",
    "comingsoon": "Prossimamente",
    "signUp.title": "Iscriviti adesso",
    "signUp.text": "Entra nel mondo del armwrestling. Lo sport più antico e accessibile. Iscriviti ora e ti faremo sapere quando l'app è stata rilasciata.",
    "signUp.part1": "Aderire",
    "signUp.part2": "adesso",
    "signup": "Creare un profilo",
    "genders.male": "Maschio",
    "genders.female": "Femmina",
    "genders.other": "Altro",
    "signUpSuccess.title": "Grazie per esserti iscritto!",
    "signUpSuccess.text": "Hai creato il tuo account per l'applicazione Armbet che arriverà presto. Controlla la tua casella di posta in arrivo per verificare e attivare il tuo account. Ricordati i tuoi dati di accesso e ti contatteremo quando l'app sarà rilasciata. Ci vediamo dall'altra parte.",
    "password": "Parola d'ordine",
    "email": "Indirizzo email",
    "fullname": "Nome e cognome",
    "fulladdress": "Indirizzo completo",
    "gender": "Genere",
    "createyouraccount": "crea il tuo account",
    "changelanguage": "Cambia la tua lingua",
    "userterms": "Termini e condizioni dell'utente",
    "privacypolicy": "Politica sulla riservatezza",
    "copyrightmessage": "© Copyright 2020 Armbet. Tutti i diritti riservati.",
    "backtohomepage": "Torna alla homepage",
    "message": "Messaggio",
    "close": "Vicino",
    "accountalreadyexists": "Un account con quell'indirizzo email esiste già. Potresti essere già entrato a far parte della community.",
    "notFound.title": "404",
    "notFound.text": "Siamo spiacenti ma non è stato possibile trovare quella pagina ...",
    "errorhasoccured": "Siamo spiacenti ma si è verificato un errore ... Ci scusiamo per l'inconveniente.",
    "networkerror": "Siamo spiacenti ma non siamo riusciti a connetterci al server ...",
    "emailcouldntsend": "Siamo spiacenti ma potremmo inviare l'e-mail. Controlla il tuo indirizzo e-mail e riprova.",
    "contactus": "Contattaci",
    "nothanks": "No grazie",
    "improvetranslationstitle": "Aiutaci a migliorare le traduzioni!",
    "improvetranslationstext": "Vi preghiamo di contattarci se si desidera contribuire a migliorare le traduzioni",

    "accountactivated": "Il tuo account è stato attivato!",
    "accountalreadyactivated": "Il vostro conto è già stato attivato!",
    "accountactivatedtext": "Hai creato il tuo account per l'app Armbet che arriverà presto. Ricordati la tua email e la password e ti contatteremo quando l'app sarà rilasciata. Ci vediamo dall'altra parte.",
    "linknotvalid": "Il link è scaduto",
    "linknotvalidtext": "Il link che hai inserito non è valido, se vuoi attivare il tuo account richiedi l'invio di una nuova e-mail alla tua casella di posta elettronica",

    "privacypolicy.1.text1": "Su armbet.com, accessibile da www.armbet.com, una delle nostre priorità principali è la privacy dei nostri visitatori. La presente informativa sulla privacy contiene i tipi di informazioni raccolte e registrate da armbet.com e il modo in cui le utilizziamo.",
    "privacypolicy.1.text2": "Se hai ulteriori domande o richiedi ulteriori informazioni sulla nostra Informativa sulla privacy, non esitare a contattarci.",
    "privacypolicy.1.text3": "La presente Informativa sulla privacy si applica solo alle nostre attività online ed è valida per i visitatori del nostro sito Web per quanto riguarda le informazioni che hanno condiviso e / o raccolto in armbet.com. Questa politica non è applicabile alle informazioni raccolte offline o tramite canali diversi da questo sito Web.",
    "privacypolicy.2.title": "Consenso",
    "privacypolicy.2.text1": "Utilizzando il nostro sito Web, acconsenti alla nostra Informativa sulla privacy e accetti i suoi termini.",
    "privacypolicy.3.title": "Informazioni che raccogliamo",
    "privacypolicy.3.text1": "Le informazioni personali che ti viene chiesto di fornire, e i motivi per cui ti viene chiesto di fornirle, ti saranno chiariti nel momento in cui ti chiediamo di fornire le tue informazioni personali.",
    "privacypolicy.3.text2": "Se ci contatti direttamente, potremmo ricevere ulteriori informazioni su di te come il tuo nome, indirizzo e-mail, numero di telefono, i contenuti del messaggio e / o gli allegati che potresti inviarci e qualsiasi altra informazione che potresti scegliere di fornire.",
    "privacypolicy.3.text3": "Quando ti registri per un Account, potremmo chiedere le tue informazioni di contatto, inclusi elementi come nome, ragione sociale, indirizzo, indirizzo e-mail e numero di telefono.",
    "privacypolicy.4.title": "Come utilizziamo le tue informazioni",
    "privacypolicy.4.text1": "Utilizziamo le informazioni che raccogliamo in vari modi, tra cui per:",
    "privacypolicy.4.text2": "Fornire, gestire e mantenere il nostro sito web",
    "privacypolicy.4.text3": "Migliora, personalizza ed espandi il nostro sito web",
    "privacypolicy.4.text4": "Comprendi e analizza come usi il nostro sito web",
    "privacypolicy.4.text5": "Sviluppa nuovi prodotti, servizi, caratteristiche e funzionalità",
    "privacypolicy.4.text6": "Comunicare con te, direttamente o tramite uno dei nostri partner, anche per l'assistenza clienti, per fornirti aggiornamenti e altre informazioni relative al sito web e per scopi di marketing e promozionali",
    "privacypolicy.4.text7": "Inviare e-mail",
    "privacypolicy.4.text8": "Trova e previene le frodi",
    "privacypolicy.5.title": "Log files",
    "privacypolicy.5.text1": "armbet.com segue una procedura standard per l'utilizzo dei file di registro. Questi file registrano i visitatori quando visitano i siti Web. Tutte le società di hosting fanno questo e parte dell'analisi dei servizi di hosting. Le informazioni raccolte dai file di registro includono indirizzi IP (Internet Protocol), tipo di browser, Internet Service Provider (ISP), data e ora, pagine di riferimento / uscita e possibilmente il numero di clic. Questi non sono collegati ad alcuna informazione che sia identificabile personalmente. Lo scopo delle informazioni è di analizzare le tendenze, amministrare il sito, tenere traccia dei movimenti degli utenti sul sito Web e raccogliere informazioni demografiche.",
    "privacypolicy.6.title": "Politiche sulla privacy dei partner pubblicitari",
    "privacypolicy.6.text1": "È possibile consultare questo elenco per trovare l'informativa sulla privacy per ciascuno dei partner pubblicitari di armbet.com.",
    "privacypolicy.6.text2": "Gli ad server o le reti pubblicitarie di terze parti utilizzano tecnologie come cookie, JavaScript o Web Beacon utilizzati nei rispettivi annunci pubblicitari e collegamenti visualizzati su armbet.com, che vengono inviati direttamente al browser degli utenti. In questo caso, ricevono automaticamente il tuo indirizzo IP. Queste tecnologie sono utilizzate per misurare l'efficacia delle loro campagne pubblicitarie e / o per personalizzare il contenuto pubblicitario che vedi sui siti web che visiti.",
    "privacypolicy.6.text3": "Si noti che armbet.com non ha accesso o controllo su questi cookie utilizzati da inserzionisti di terze parti.",
    "privacypolicy.7.title": "Informative sulla privacy di terzi",
    "privacypolicy.7.text1": "La politica sulla privacy di armbet.com non si applica ad altri inserzionisti o siti Web. Pertanto, ti consigliamo di consultare le rispettive Politiche sulla privacy di questi ad server di terze parti per informazioni più dettagliate. Può includere le loro pratiche e istruzioni su come rinunciare a determinate opzioni. È possibile trovare un elenco completo di queste politiche sulla privacy e dei relativi collegamenti qui: Collegamenti sulla politica sulla privacy.",
    "privacypolicy.7.text2": "Puoi scegliere di disabilitare i cookie tramite le opzioni del tuo browser. Per conoscere informazioni più dettagliate sulla gestione dei cookie con browser Web specifici, è possibile trovarli nei rispettivi siti Web dei browser. Cosa sono i cookie",
    "privacypolicy.8.title": "Diritti sulla privacy CCPA (non vendere le mie informazioni personali)",
    "privacypolicy.8.text1": "Ai sensi della CCPA, tra gli altri diritti, i consumatori della California hanno il diritto di:",
    "privacypolicy.8.text2": "Richiedere a un'azienda che raccoglie i dati personali di un consumatore di divulgare le categorie e i pezzi specifici di dati personali che un'azienda ha raccolto sui consumatori.",
    "privacypolicy.8.text3": "Richiedere a un'azienda di eliminare tutti i dati personali sul consumatore raccolti da un'azienda.",
    "privacypolicy.8.text4": "Richiedere a un'azienda che vende i dati personali di un consumatore, non di vendere i dati personali del consumatore.",
    "privacypolicy.8.text5": "Se fai una richiesta, abbiamo un mese per rispondere a te. Se desideri esercitare uno di questi diritti, ti preghiamo di contattarci.",
    "privacypolicy.9.title": "Diritti di protezione dei dati GDPR",
    "privacypolicy.9.text1": "Desideriamo assicurarci che tu sia pienamente consapevole di tutti i tuoi diritti di protezione dei dati. Ogni utente ha diritto a quanto segue:",
    "privacypolicy.9.text2": "Il diritto di accesso - Hai il diritto di richiedere copie dei tuoi dati personali. Potremmo addebitare una piccola commissione per questo servizio.",
    "privacypolicy.9.text3": "Il diritto di rettifica - Hai il diritto di richiedere la correzione di qualsiasi informazione che ritieni inaccurata. Hai anche il diritto di richiedere che completiamo le informazioni che ritieni incomplete.",
    "privacypolicy.9.text4": "Il diritto alla cancellazione - Hai il diritto di richiedere che cancelliamo i tuoi dati personali, a determinate condizioni.",
    "privacypolicy.9.text5": "Il diritto di limitare l'elaborazione - Hai il diritto di richiedere che limitiamo il trattamento dei tuoi dati personali, a determinate condizioni.",
    "privacypolicy.9.text6": "Il diritto di opporsi al trattamento: l'utente ha il diritto di opporsi al nostro trattamento dei dati personali, a determinate condizioni.",
    "privacypolicy.9.text7": "Il diritto alla portabilità dei dati - Hai il diritto di richiedere che trasferiamo i dati che abbiamo raccolto a un'altra organizzazione, o direttamente a te, a determinate condizioni.",
    "privacypolicy.9.text8": "Se fai una richiesta, abbiamo un mese per rispondere a te. Se desideri esercitare uno di questi diritti, ti preghiamo di contattarci.",
    "privacypolicy.10.title": "Informazioni per bambini",
    "privacypolicy.10.text1": "Un'altra parte della nostra priorità è l'aggiunta di protezione per i bambini durante l'utilizzo di Internet. Incoraggiamo genitori e tutori a osservare, partecipare e / o monitorare e guidare la loro attività online.",
    "privacypolicy.10.text2": "armbet.com non raccoglie consapevolmente alcuna informazione personale identificabile da bambini di età inferiore ai 13 anni. Se ritieni che tuo figlio abbia fornito questo tipo di informazioni sul nostro sito Web, ti consigliamo vivamente di contattarci immediatamente e faremo del nostro meglio per rimuovere tempestivamente tali informazioni dai nostri archivi.",
    "user.terms.1.text1": "Questi termini e condizioni delineano le regole e i regolamenti per l'uso di Armbet, sito Web di Inc.",
    "user.terms.1.text2": "Armbet, Inc si trova a:",
    "user.terms.1.text3": "Leslie St 5863 Unit 610 North York <br /> M2H 1J8 - Ontario, Canada",
    "user.terms.1.text4": "Accedendo a questo sito Web assumiamo che tu accetti questi termini e condizioni per intero. Non continuare a utilizzare il sito Web di Armbet, Inc. se non si accettano tutti i termini e le condizioni indicati in questa pagina.",
    "user.terms.1.text5": "La seguente terminologia si applica a questi Termini e condizioni, Dichiarazione sulla privacy e Dichiarazione di non responsabilità e qualsiasi o tutti gli Accordi: 'Cliente', 'Tu' e 'Tuo' si riferiscono a te, la persona che accede a questo sito Web e accetta i termini e le condizioni della Società. 'La Società', 'Noi stessi', 'Noi', 'Nostro' e 'Noi' si riferisce alla nostra Società. 'Parte', 'Parti' o 'Noi' si riferiscono sia al Cliente sia a noi stessi, o al Cliente o noi stessi. Tutti i termini si riferiscono all'offerta, all'accettazione e al corrispettivo del pagamento necessari per intraprendere il processo della nostra assistenza al Cliente nel modo più appropriato, sia attraverso riunioni formali di durata fissa, sia con qualsiasi altro mezzo, allo scopo esplicito di soddisfare Esigenze del cliente in relazione alla fornitura dei servizi / prodotti dichiarati dalla Società, in conformità e in conformità con la legge prevalente del Canada. Qualsiasi uso della suddetta terminologia o altre parole in singolare, plurale, maiuscolo e / o lui / lei o loro, sono considerati intercambiabili e quindi riferiti allo stesso.",
    "user.terms.2.title": "Collegamento ipertestuale al nostro contenuto",
    "user.terms.2.text1": "Le seguenti organizzazioni possono collegarsi al nostro sito Web senza previa approvazione scritta:",
    "user.terms.2.text2": "Agenzie governative;",
    "user.terms.2.text3": "Motori di ricerca;",
    "user.terms.2.text4": "Organizzazioni giornalistiche;",
    "user.terms.2.text5": "I distributori di directory online che ci elencano nella directory possono collegarsi al nostro sito Web nello stesso modo in cui collegano i siti Web di altre attività commerciali elencate;",
    "user.terms.2.text6": "Aziende accreditate a livello di sistema, ad eccezione di organizzazioni non profit, centri commerciali di beneficenza e gruppi di raccolta fondi di beneficenza che potrebbero non essere collegati al nostro sito Web.",
    "user.terms.2.text7": "Queste organizzazioni possono collegarsi alla nostra home page, alle pubblicazioni o ad altre informazioni del sito Web purché il collegamento: (a) non sia in alcun modo fuorviante; (b) non implica falsamente la sponsorizzazione, l'approvazione o l'approvazione della parte che collega e dei suoi prodotti o servizi; e (c) si inserisce nel contesto del sito della parte che collega.",
    "user.terms.2.text8": "Possiamo prendere in considerazione e approvare a nostra esclusiva discrezione altre richieste di link dai seguenti tipi di organizzazioni:",
    "user.terms.2.text9": "fonti di informazioni di consumo e / o commerciali comunemente note come Camere di commercio, American Automobile Association, AARP e Consumers Union;",
    "user.terms.2.text10": "siti della community dot.com;",
    "user.terms.2.text11": "associazioni o altri gruppi che rappresentano enti di beneficenza, compresi siti di beneficenza,",
    "user.terms.2.text12": "distributori di elenchi online;",
    "user.terms.2.text13": "portali internet;",
    "user.terms.2.text14": "studi contabili, legali e di consulenza i cui clienti principali sono le imprese; e",
    "user.terms.2.text15": "istituzioni educative e associazioni di categoria.",
    "user.terms.2.text16": "Approveremo le richieste di link di queste organizzazioni se stabiliamo che: (a) il link non si rifletterà in modo sfavorevole su di noi o sulle nostre aziende accreditate (ad esempio, associazioni di categoria o altre organizzazioni che rappresentano tipi di attività intrinsecamente sospetti, come work-at- opportunità di casa, non deve essere consentito il collegamento); (b) l'organizzazione non ha precedenti insoddisfacenti con noi; (c) il vantaggio per noi della visibilità associata al collegamento ipertestuale supera l'assenza di Armbet, Inc; e (d) laddove il collegamento sia nel contesto di informazioni generali sulle risorse o sia altrimenti coerente con il contenuto editoriale di una newsletter o di un prodotto simile che promuova la missione dell'organizzazione.",
    "user.terms.2.text17": "Queste organizzazioni possono collegarsi alla nostra home page, alle pubblicazioni o ad altre informazioni del sito Web purché il collegamento: (a) non sia in alcun modo fuorviante; (b) non implica falsamente la sponsorizzazione, l'approvazione o l'approvazione della parte che collega e dei suoi prodotti o servizi; e (c) si inserisce nel contesto del sito della parte che collega.",
    "user.terms.2.text18": "Se sei tra le organizzazioni elencate al paragrafo 2 sopra e sei interessato a collegarti al nostro sito web, devi avvisarci inviando una e-mail a",
    "user.terms.2.text19": "Includi il tuo nome, il nome della tua organizzazione, le informazioni di contatto (come un numero di telefono e / o indirizzo e-mail) e l'URL del tuo sito, un elenco di tutti gli URL da cui intendi collegarti al nostro sito Web, e un elenco degli URL sul nostro sito a cui desideri collegarti. Concedi 2-3 settimane per una risposta.",
    "user.terms.2.text20": "Le organizzazioni approvate possono collegarsi al nostro sito Web come segue:",
    "user.terms.2.text21": "Utilizzando il nostro nome aziendale; o",
    "user.terms.2.text22": "Utilizzando il localizzatore di risorse uniforme (indirizzo Web) collegato; o",
    "user.terms.2.text23": "Utilizzando qualsiasi altra descrizione del nostro sito Web o materiale collegato a ciò ha senso nel contesto e nel formato del contenuto sul sito della parte che collega.",
    "user.terms.2.text24": "Non sarà consentito l'uso di Armbet, il logo di Inc o altre opere d'arte per il collegamento in assenza di un accordo di licenza del marchio.",
    "user.terms.3.title": "Rimozione di collegamenti dal nostro sito Web",
    "user.terms.3.text1": "Se trovi qualsiasi link sul nostro sito Web o qualsiasi sito Web collegato discutibile per qualsiasi motivo, puoi contattarci al riguardo. Prenderemo in considerazione le richieste di rimozione dei collegamenti ma non avremo l'obbligo di farlo o di rispondere direttamente a te.",
    "user.terms.3.text2": "Sebbene ci sforziamo di garantire che le informazioni su questo sito Web siano corrette, non garantiamo la sua completezza o accuratezza; né ci impegniamo a garantire che il sito Web rimanga disponibile o che il materiale sul sito Web sia aggiornato.",
    "user.terms.4.title": "Responsabilità dei contenuti",
    "user.terms.4.text1": "Non avremo alcuna responsabilità per qualsiasi contenuto che appare sul tuo sito Web. Accetti di indennizzare e difenderci da tutti i reclami derivanti o basati sul tuo sito Web. Nessun link può apparire su qualsiasi pagina del tuo sito Web o in qualsiasi contesto che contenga contenuti o materiali che possono essere interpretati come calunniosi, osceni o criminali o che violano, altrimenti violano o sostengono la violazione o altre violazioni di, qualsiasi diritti di terzi.",
    "user.terms.5.title": "disconoscimento",
    "user.terms.5.text1": "Nella misura massima consentita dalla legge applicabile, escludiamo tutte le dichiarazioni, garanzie e condizioni relative al nostro sito Web e all'utilizzo di questo sito Web (inclusi, a titolo esemplificativo, tutte le garanzie implicite dalla legge in merito a qualità soddisfacente, idoneità allo scopo e / o l'uso di cure e abilità ragionevoli). Nulla in questo disclaimer:",
    "user.terms.5.text2": "limitare o escludere la nostra o la vostra responsabilità per morte o lesioni personali derivanti da negligenza;",
    "user.terms.5.text3": "limitare o escludere la nostra o la vostra responsabilità per frode o dichiarazione fraudolenta;",
    "user.terms.5.text4": "limitare le nostre o le vostre responsabilità in qualsiasi modo non consentito dalla legge applicabile; o",
    "user.terms.5.text5": "escludere una qualsiasi delle nostre o vostre responsabilità che non possono essere escluse dalla legge applicabile.",
    "user.terms.5.text6": "Le limitazioni e le esclusioni di responsabilità stabilite nella presente Sezione e altrove in questo disclaimer: (a) sono soggette al paragrafo precedente; e (b) disciplinare tutte le responsabilità derivanti dal disclaimer o in relazione all'oggetto del presente disclaimer, comprese le responsabilità derivanti dal contratto, in illecito civile (inclusa la negligenza) e per violazione del dovere legale.",
    "user.terms.5.text7": "Nella misura in cui il sito Web e le informazioni e i servizi sul sito Web sono forniti gratuitamente, non saremo responsabili per eventuali perdite o danni di qualsiasi natura."
}