import regex from "./regex";
import store from '../state/createStore';
import i18n from "../i18n";
import c from './globals';

var timer = "";
const functions = {
    validateField: (text, type) => {
        if(!text) return -1;
        if (regex[type].test(text)) return 1;
        return 0;
    },
    changeLanguage: (lang) => {
        i18n.changeLanguage(lang);
        c.language = lang;
        //document.location.reload(true);
    },
    formatField: (text, type) => {
        if (!type) {
            return text.trim();
        } else if( type === "email") {
            return text.trim().toLowerCase();
        } else if( type === "fullName") {
            var splitText = text.toLowerCase().split(' ');
            for (var i = 0; i < splitText.length; i++) {
                splitText[i] = splitText[i].charAt(0).toUpperCase() + splitText[i].substring(1);     
            }
            return splitText.join(' ').trim();
        } else {
            return text.trim();
        }
    },
    todaysDate: () => {
        var date = new Date();
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        date = mm + "/" + dd + "/" + yyyy;
        return date;
    },
    todaysMonthAndYear: () => {
        var date = new Date();
        var mm = date.getMonth() + 1; //January is 0!
        var yyyy = date.getFullYear();
        if (mm < 10) {
            mm = "0" + mm;
        }
        date = mm + "/" + yyyy;
        return date;
    }
}


export default functions;