export default {
    "_colon_": " : ",
    "languages.english":"Anglais",
    "languages.french":"Français",
    "languages.spanish" : "Espagnol",
    "languages.german":"Allemand",
    "languages.hindi":"Hindi",
    "languages.italian":"Italien",
    "languages.georgian":"Géorgien",
    "languages.portuguese":"Portugais",
    "languages.russian":"Russe",
    "languages.swedish":"Suédois",
    "languages.turkish":"Turc",
    "languages.ukrainian":"Ukrainien",
    "logoAlt": "Logo de Armbet",
    "navBar.item1.text" : "Comment ça marche ?",
    "navBar.item2.text":"Nouveautés à venir",
    "navBar.item3.text":"Créer un compte",
    "headline" : "Bienvenue dans le monde du bras de fer",
    "headline.part1" : "Bienvenue dans le monde du",
    "headline.part2" : "bras de fer",
    "headlineText":"Armbet est une application de bras de fer qui vous permet de trouver des partenaires pour vous entrainer ou pour les compétitions, avec de nombreuses autres fonctionnalités à venir",
    "about.title": "Devenir membre",
    "about.text":"Une fois que vous aurez créé un profil, vous rejoindrez la communauté de bras de fer. Vous pourrez trouver d'autres personnes autour de vous en utilisant notre carte, pour ensuite leur envoyer des messages et vous rencontrer.",
    "features1.title":"Trouver",
    "features1.text" : "Trouvez des brasseurs dans votre région ou région d'intérêt",
    "features2.title":"Discuter",
    "features2.text":"Envoyez des essages aux autres utilisateurs pour mettre en place des activités ensemble",
    "features3.title":"S'entrainer",
    "features3.text":"Rejoingnez-vous pour vous entrainer ensemble ou entrer dans des compétitions.",
    "comingSoon.title" : "Trouvez et créez des événements, rejoignez les rangs !",
    "comingSoon.text" : "Beaucoup de nouvelles fonctionnalités sont en préparation ! Bientôt, vous pourrez trouver des événements et compétitions autour de vous et même créer les vôtres. Un système de classement complet de tous utilisateurs est également prévu !",
    "comingSoon.part1":"Trouvez et créez",
    "comingSoon.part2" : "des événements",
    "comingSoon.part3":", rejoignez les",
    "comingSoon.part4":" rangs ",
    "comingsoon": "Arrive prochainement",
    "signUp.title":"Rejoingnez-nous dès maintenant",
    "signUp.text" : "Entrez dans le monde du bras de fer. Le sport le plus ancien et le plus accessible. Créez votre compte maintenant et nous voous informerons lorsque l'application est disponible.",
    "signUp.part1":"Rejoindrez-nous",
    "signUp.part2" : "dès maintenant",
    "signup" : "Créer un compte",
    "genders.male" : "Homme",
    "genders.female" : "Femme",
    "genders.other":"Autre",
    "signUpSuccess.title" : "Merci de vous être inscrit !",
    "signUpSuccess.text":"Vous avez créé votre compte pour l'application Armbet qui sera bientôt disponible. Veuillez vérifier votre boîte mail pour vérifier et activer votre compte. Conservez vos données de connexion et nous vous contacterons lorsque l'application sera disponible. A bientôt !",
    "password" : "Mot de passe",
    "email" : "Adresse mail",
    "fullname" : "Prénom et nom",
    "fulladdress" : "Adresse complète",
    "gender" : "Sexe",
    "createyouraccount" : "Créez votre compte",
    "changelanguage" : "Changez de langue",
    "userterms" : "Conditions d'utilisation",
    "privacypolicy" : "Politique de confidentialité",
    "copyrightmessage" : "© Copyright 2020 Armbet. Tous droits réservés",
    "backtohomepage" : "Retour à la page d'accueil",
    "message" : "Message",
    "close" : "Fermer",
    "accountalreadyexists" : "Un compte avec cette adresse mail existe déjà. Vous avez peut-être déjà rejoint la communauté.",
    "notFound.title": "404",
    "notFound.text": "Nous sommes désolés mais cette page n'a pas pu être trouvée...",
    "errorhasoccured": "Nous sommes désolés mais une erreur s'est produite... Désolé pour le désagrément. ",
    "networkerror": "Nous sommes désolés mais nous n'avons pas pu nous connecter au serveur...",
    "emailcouldntsend": "Nous sommes désolés, mais nous n'avons pas réussi à envoyer le mail. Veuillez vérifier votre adresse mail et réessayer.",
    "contactus": "Contactez-nous",
    "nothanks": "Non merci",

    "accountactivated" : "Votre compte a été activé !",
    "accountalreadyactivated" : "Votre compte a déjà été activé !",
    "accountactivatedtext": "Vous avez créé votre compte pour l'application Armbet qui sera bientôt disponible. Souvenez-vous de votre adresse électronique et de votre mot de passe et nous vous contacterons lorsque l'application sera disponible. A bientôt de l'autre côté",
    "linknotvalid" : "Le lien a expiré",
    "linknotvalidtext" : "Le lien que vous avez entré n'est pas valide, si vous voulez activer votre compte, veuillez demander qu'un nouveau mail soit envoyé à votre adresse",

    "privacypolicy.1.text1" : "Sur armbet.com, accessible depuis www.armbet.com, l'une de nos principales priorités est la protection de la vie privée de nos visiteurs. Ce document relatif à la politique de confidentialité contient des types d'informations qui sont collectées et enregistrées par armbet.com et la façon dont nous les utilisons.",
    "privacypolicy.1.text2" : "Si vous avez des questions supplémentaires ou si vous souhaitez obtenir plus d'informations sur notre politique de confidentialité, n'hésitez pas à nous contacter.",
    "privacypolicy.1.text3" : "Cette politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site web en ce qui concerne les informations qu'ils ont partagées et/ou collectées sur armbet.com. Cette politique n'est pas applicable aux informations collectées hors ligne ou par des canaux autres que ce site web.",
    "privacypolicy.2.title" : "Consentement",
    "privacypolicy.2.text1" : "En utilisant notre site web, vous acceptez par la présente notre politique de confidentialité et vous en acceptez les conditions.",
    "privacypolicy.3.title" : "Informations que nous recueillons",
    "privacypolicy.3.text1" : "Les informations personnelles qui vous sont demandées, et les raisons pour lesquelles elles vous sont demandées, vous seront clairement indiquées au moment où nous vous demanderons de fournir vos informations personnelles.",
    "privacypolicy.3.text2" : "Si vous nous contactez directement, nous pouvons recevoir des informations supplémentaires vous concernant, telles que votre nom, votre adresse électronique, votre numéro de téléphone, le contenu du message et/ou des pièces jointes que vous pouvez nous envoyer, et toute autre information que vous choisissez de fournir.",
    "privacypolicy.3.text3" : "Lorsque vous vous inscrivez pour un compte, nous pouvons vous demander vos coordonnées, y compris des éléments tels que votre nom, le nom de votre société, votre adresse, votre adresse électronique et votre numéro de téléphone.",
    "privacypolicy.4.title" : "Comment nous utilisons vos informations",
    "privacypolicy.4.text1" : "Nous utilisons les informations que nous recueillons de différentes manières, notamment pour :",
    "privacypolicy.4.text2" : "Fournir, exploiter et entretenir notre site web.",
    "privacypolicy.4.text3" : "Améliorer, personnaliser et développer notre site web.",
    "privacypolicy.4.text4" : "Comprendre et analyser la façon dont vous utilisez notre site web.",
    "privacypolicy.4.text5" : "Développer de nouveaux produits, services, caractéristiques et fonctionnalités.",
    "privacypolicy.4.text6" : "Communiquer avec vous, soit directement, soit par l'intermédiaire de l'un de nos partenaires, y compris pour le service clientèle, pour vous fournir des mises à jour et d'autres informations relatives au site web, ainsi qu'à des fins de marketing et de promotion.",
    "privacypolicy.4.text7" : "vous envoyer des courriels.",
    "privacypolicy.4.text8" : "Trouver et prévenir la fraude.",
    "privacypolicy.5.title" : "Fichiers journaux",
    "privacypolicy.5.text1" : "armbet.com suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites web. Toutes les entreprises d'hébergement le font et une partie des analyses des services d'hébergement. Les informations recueillies par les fichiers journaux comprennent les adresses de protocole internet (IP), le type de navigateur, le fournisseur d'accès internet (FAI), la date et l'heure, les pages de renvoi/sortie et éventuellement le nombre de clics. Ces données ne sont pas liées à des informations permettant d'identifier une personne. L'objectif de ces informations est d'analyser les tendances, d'administrer le site, de suivre les déplacements des utilisateurs sur le site et de recueillir des informations démographiques.",
    "privacypolicy.6.title" : "Politiques de confidentialité des partenaires publicitaires",
    "privacypolicy.6.text1" : "Vous pouvez consulter cette liste pour trouver la politique de confidentialité de chacun des partenaires publicitaires de armbet.com.",
    "privacypolicy.6.text2" : "Les serveurs ou réseaux publicitaires tiers utilisent des technologies telles que les cookies, JavaScript ou les balises Web qui sont utilisées dans leurs publicités respectives et les liens qui apparaissent sur armbet.com, qui sont envoyés directement au navigateur des utilisateurs. Ils reçoivent automatiquement votre adresse IP lorsque cela se produit. Ces technologies sont utilisées pour mesurer l'efficacité de leurs campagnes publicitaires et/ou pour personnaliser le contenu publicitaire que vous voyez sur les sites web que vous visitez.",
    "privacypolicy.6.text3" : "Notez que armbet.com n'a pas accès à ces cookies qui sont utilisés par des annonceurs tiers, ni ne les contrôle.",
    "privacypolicy.7.title" : "Politiques de protection de la vie privée des tiers",
    "privacypolicy.7.text1" : "La privacypolicy de armbet.com ne s'applique pas aux autres annonceurs ou sites web. Par conséquent, nous vous conseillons de consulter les politiques de confidentialité respectives de ces serveurs publicitaires tiers pour obtenir des informations plus détaillées. Elle peut inclure leurs pratiques et instructions sur la manière de refuser certaines options. Vous trouverez une liste complète de ces politiques de confidentialité et de leurs liens ici : Liens vers les politiques de protection de la vie privée.",
    "privacypolicy.7.text2" : "Vous pouvez choisir de désactiver les cookies par le biais des options de votre navigateur. Pour obtenir des informations plus détaillées sur la gestion des cookies avec des navigateurs web spécifiques, vous pouvez consulter les sites web respectifs des navigateurs. Que sont les cookies ?",
    "privacypolicy.8.title" : "Droit à la vie privée de l'ACCP (ne pas vendre mes informations personnelles)",
    "privacypolicy.8.text1" : "En vertu de la CCPA, les consommateurs californiens ont notamment le droit de :",
    "privacypolicy.8.text2" : "Demander qu'une entreprise qui collecte les données personnelles d'un consommateur divulgue les catégories et les éléments spécifiques des données personnelles qu'elle a collectées sur les consommateurs.",
    "privacypolicy.8.text3" : "Demander à une entreprise de supprimer toute donnée à caractère personnel concernant le consommateur qu'elle a recueillie.",
    "privacypolicy.8.text4" : "Demander qu'une entreprise qui vend les données personnelles d'un consommateur ne vende pas les données personnelles du consommateur.",
    "privacypolicy.8.text5" : "Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter.",
    "privacypolicy.9.title" : "Droits de protection des données de la GRPD",
    "privacypolicy.9.text1" : "Nous souhaitons nous assurer que vous êtes pleinement conscients de tous vos droits en matière de protection des données. Chaque utilisateur a droit à ce qui suit :",
    "privacypolicy.9.text2" : "Le droit d'accès - Vous avez le droit de demander des copies de vos données personnelles. Nous pouvons vous facturer une petite somme pour ce service.",
    "privacypolicy.9.text3" : "Le droit de rectification - Vous avez le droit de nous demander de corriger toute information que vous jugez inexacte. Vous avez également le droit de nous demander de compléter les informations que vous jugez incomplètes.",
    "privacypolicy.9.text4" : "Le droit d'effacement - Vous avez le droit de demander que nous effacions vos données personnelles, sous certaines conditions.",
    "privacypolicy.9.text5" : "Le droit de restreindre le traitement - Vous avez le droit de demander que nous restreignions le traitement de vos données à caractère personnel, sous certaines conditions.",
    "privacypolicy.9.text6" : "Droit d'opposition au traitement - Vous avez le droit de vous opposer au traitement de vos données à caractère personnel, sous certaines conditions.",
    "privacypolicy.9.text7" : "Le droit à la portabilité des données - Vous avez le droit de demander que nous transférions les données que nous avons collectées à une autre organisation, ou directement à vous, sous certaines conditions.",
    "privacypolicy.9.text8" : "Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter.",
    "privacypolicy.10.title" : "Information sur les enfants",
    "privacypolicy.10.text1" : "Une autre partie de notre priorité est d'ajouter la protection des enfants lors de l'utilisation de l'internet. Nous encourageons les parents et les tuteurs à observer, à participer et/ou à surveiller et à guider leurs activités en ligne.",
    "privacypolicy.10.text2" : "armbet.com ne collecte sciemment aucune information d'identification personnelle d'enfants de moins de 13 ans. Si vous pensez que votre enfant a fourni ce type d'informations sur notre site, nous vous encourageons vivement à nous contacter immédiatement et nous ferons notre possible pour supprimer rapidement ces informations de nos dossiers.",
    "user.terms.1.text1": "Ces termes et conditions décrivent les règles et règlements pour l'utilisation du site Web d'Armbet, Inc.",
    "user.terms.1.text2": "Armbet, Inc est situé à:",
    "user.terms.1.text3": "Leslie St 5863 Unit 610 North York <br /> M2H 1J8 - Ontario, Canada",
    "user.terms.1.text4": "En accédant à ce site Web, nous supposons que vous acceptez ces termes et conditions dans leur intégralité. Ne continuez pas à utiliser le site Web d'Armbet, Inc. si vous n'acceptez pas tous les termes et conditions énoncés sur cette page.",
    "user.terms.1.text5": "La terminologie suivante s'applique aux présentes conditions générales, à la déclaration de confidentialité et à l'avis de non-responsabilité et à tout ou partie des accords: `` client '', `` vous '' et `` votre '' font référence à vous, la personne accédant à ce site Web et acceptant les conditions générales de la société. «La société», «nous-mêmes», «nous», «notre» et «nous» fait référence à notre société. «Partie», «Parties» ou «Nous» fait référence à la fois au Client et à nous-mêmes, ou au Client ou à nous-mêmes. Tous les termes se réfèrent à l'offre, l'acceptation et la considération du paiement nécessaires pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée, que ce soit par des réunions formelles d'une durée fixe, ou tout autre moyen, dans le but exprès de rencontrer le Les besoins du client en ce qui concerne la fourniture des services / produits déclarés de la société, conformément et sous réserve des lois en vigueur du Canada. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, en majuscules et / ou il ou elles, sont considérés comme interchangeables et donc comme faisant référence à ceux-ci.",
    "user.terms.2.title": "Hyperlien vers notre contenu",
    "user.terms.2.text1": "Les organisations suivantes peuvent créer un lien vers notre site Web sans autorisation écrite préalable:",
    "user.terms.2.text2": "Organismes gouvernementaux;",
    "user.terms.2.text3": "Moteurs de recherche;",
    "user.terms.2.text4": "Organismes de presse;",
    "user.terms.2.text5": "Les distributeurs d'annuaires en ligne, lorsqu'ils nous inscrivent dans l'annuaire, peuvent créer un lien vers notre site Web de la même manière qu'ils renvoient vers les sites Web d'autres entreprises cotées;",
    "user.terms.2.text6": "Entreprises accréditées à l'échelle du système, à l'exception de la sollicitation d'organisations à but non lucratif, de centres commerciaux de bienfaisance et de groupes de collecte de fonds caritatifs qui ne peuvent pas créer de lien hypertexte vers notre site Web.",
    "user.terms.2.text7": "Ces organisations peuvent créer un lien vers notre page d'accueil, des publications ou d'autres informations de site Web tant que le lien: (a) n'est en aucun cas trompeur; (b) n'implique pas faussement le parrainage, l'approbation ou l'approbation de la partie de liaison et de ses produits ou services; et (c) s'inscrit dans le contexte du site de la partie de liaison.",
    "user.terms.2.text8": "Nous pouvons considérer et approuver à notre seule discrétion d'autres demandes de lien des types d'organisations suivants:",
    "user.terms.2.text9": "des sources d'information sur les consommateurs et / ou les entreprises connues telles que les chambres de commerce, l'American Automobile Association, l'AARP et le Consumers Union;",
    "user.terms.2.text10": "sites communautaires dot.com;",
    "user.terms.2.text11": "les associations ou autres groupes représentant des organismes de bienfaisance, y compris les sites de dons caritatifs,",
    "user.terms.2.text12": "distributeurs d'annuaires en ligne;",
    "user.terms.2.text13": "portails Internet;",
    "user.terms.2.text14": "cabinets comptables, juridiques et de conseil dont les principaux clients sont des entreprises; et",
    "user.terms.2.text15": "établissements d'enseignement et associations professionnelles.",
    "user.terms.2.text16": "Nous approuverons les demandes de lien de ces organisations si nous déterminons que: (a) le lien ne nuirait pas à nous ou à nos entreprises accréditées (par exemple, les associations professionnelles ou d'autres organisations représentant des types d'entreprise intrinsèquement suspects, comme le travail en entreprise) les possibilités d'accueil, ne seront pas autorisés à faire le lien); (b) l'organisation n'a pas de dossier insatisfaisant avec nous; (c) l'avantage pour nous de la visibilité associée à l'hyperlien l'emporte sur l'absence d'Armbet, Inc; et (d) lorsque le lien se trouve dans le contexte d'informations générales sur les ressources ou est autrement compatible avec le contenu éditorial d'un bulletin d'information ou d'un produit similaire faisant avancer la mission de l'organisation.",
    "user.terms.2.text17": "Ces organisations peuvent créer un lien vers notre page d'accueil, des publications ou d'autres informations de site Web tant que le lien: (a) n'est en aucun cas trompeur; (b) n'implique pas faussement le parrainage, l'approbation ou l'approbation de la partie de liaison et de ses produits ou services; et (c) s'inscrit dans le contexte du site de la partie de liaison.",
    "user.terms.2.text18": "Si vous faites partie des organisations énumérées au paragraphe 2 ci-dessus et souhaitez créer un lien vers notre site Web, vous devez nous en informer en envoyant un e-mail à",
    "user.terms.2.text19": "Veuillez inclure votre nom, le nom de votre organisation, vos coordonnées (comme un numéro de téléphone et / ou une adresse e-mail) ainsi que l'URL de votre site, une liste de toutes les URL à partir desquelles vous avez l'intention de créer un lien vers notre site Web, et une liste des URL de notre site vers lesquelles vous souhaitez créer un lien. Prévoyez 2-3 semaines pour une réponse.",
    "user.terms.2.text20": "Les organisations approuvées peuvent créer un lien hypertexte vers notre site Web comme suit:",
    "user.terms.2.text21": "En utilisant notre raison sociale; ou",
    "user.terms.2.text22": "En utilisant le localisateur de ressources uniforme (adresse Web) lié à; ou",
    "user.terms.2.text23": "En utilisant toute autre description de notre site Web ou du matériel lié à qui a du sens dans le contexte et le format du contenu sur le site de la partie de liaison.",
    "user.terms.2.text24": "Aucune utilisation du logo d'Armbet, Inc ou d'autres illustrations ne sera autorisée pour le lien en l'absence d'un accord de licence de marque.",
    "user.terms.3.title": "Suppression de liens de notre site Web",
    "user.terms.3.text1": "Si vous trouvez un lien sur notre site Web ou tout site Web lié répréhensible pour quelque raison que ce soit, vous pouvez nous contacter à ce sujet. Nous considérerons les demandes de suppression de liens mais n'aurons aucune obligation de le faire ou de vous répondre directement.",
    "user.terms.3.text2": "Bien que nous nous efforcions de nous assurer que les informations sur ce site Web sont correctes, nous ne garantissons pas leur exhaustivité ou leur exactitude; nous ne nous engageons pas non plus à veiller à ce que le site Web reste disponible ou à ce que le contenu du site Web soit mis à jour.",
    "user.terms.4.title": "Responsabilité du contenu",
    "user.terms.4.text1": "Nous déclinons toute responsabilité quant à tout contenu apparaissant sur votre site Web. Vous acceptez de nous indemniser et de nous défendre contre toute réclamation découlant de ou basée sur votre site Web. Aucun lien ne peut apparaître sur une page de votre site Web ou dans un contexte contenant du contenu ou des éléments pouvant être interprétés comme diffamatoires, obscènes ou criminels, ou qui enfreignent, violent autrement, ou préconisent l'infraction ou toute autre violation de, tout droits des tiers.",
    "user.terms.5.title": "Avertissement",
    "user.terms.5.text1": "Dans la mesure maximale permise par la loi applicable, nous excluons toutes les représentations, garanties et conditions relatives à notre site Web et à l'utilisation de ce site Web (y compris, sans limitation, toutes les garanties implicites par la loi en ce qui concerne la qualité satisfaisante, l'adéquation à l'usage et / ou l'utilisation de soins et de compétences raisonnables). Rien dans cette clause de non-responsabilité ne:",
    "user.terms.5.text2": "limiter ou exclure notre ou votre responsabilité en cas de décès ou de blessure résultant d'une négligence;",
    "user.terms.5.text3": "limiter ou exclure notre ou votre responsabilité pour fraude ou fausse déclaration frauduleuse;",
    "user.terms.5.text4": "limiter nos ou vos responsabilités d'une manière non autorisée par la loi applicable; ou",
    "user.terms.5.text5": "exclure tout ou partie de nos responsabilités qui ne peuvent être exclues en vertu de la loi applicable.",
    "user.terms.5.text6": "Les limitations et exclusions de responsabilité énoncées dans cette section et ailleurs dans cette clause de non-responsabilité: (a) sont soumises au paragraphe précédent; et (b) régir toutes les responsabilités découlant de la clause de non-responsabilité ou en relation avec l'objet de la présente clause de non-responsabilité, y compris les responsabilités découlant du contrat, en responsabilité délictuelle (y compris la négligence) et en cas de manquement à une obligation légale.",
    "user.terms.5.text7": "Dans la mesure où le site Web et les informations et services sur le site Web sont fournis gratuitement, nous ne serons pas responsables de toute perte ou dommage de quelque nature que ce soit."
}