import React from "react";
import theme from '../theme';
import { connect } from "react-redux";
import { css } from 'glamor';
import { sendActivation, setLoading } from "../state/reducer";
import TextBlock from "../elements/TextBlock";
import "../theme/font-faces.css";
import "../theme/layout.css";
import '../i18n';
import trans from "../i18n";
import Icon from "../components/Icon";


class Activate extends React.Component {
    state = {
      id: "",
      idValidationState: 0,
      showContent: false
    };
      
    componentWillMount() {
      this.sendActivation();
    }

    sendActivation = async() => {
        var url = window.location.pathname.split("/activate/").pop();
        try {
            var response = await this.props.sendActivation(url);
        } catch(err) {
            console.log("err : ",err);
        }
        this.setState({showContent:true,success:response});
    }

    render() {
        if (this.state.showContent) {
            return (
              <div className="col-center" style={{paddingTop:"15vh",backgroundColor:"#F4F6F8",width:"100vw",height:"85vh"}}>
                    <div
                        className={styles.box}
                    >   
                        <TextBlock 
                            centered
                            maxWidth="800px"
                            containerStyle={{margin: "auto"}}
                            titleElement={{text:trans.t(this.state.success === 2  ? "accountactivated" : (this.state.success === 1 ? "accountalreadyactivated" : "linknotvalid")),type:"h3"}} 
                            textElement={{text:trans.t(this.state.success === 2 || this.state.success === 1  ? "accountactivatedtext" : "linknotvalidtext"),size:1}}
                        ></TextBlock>
                    </div>
                </div>

            )
        }
        return (
          <div className="col-center-center" style={{width:"100vw",height:"100vh"}}>
              <Icon iconStyle={{height:"50px"}} icon={theme.inlineLoader} /> 
          </div>
        )
    }
          
  
}


const styles = {
  box: css({
      zIndex: 2,
      backgroundColor: "white",
      backgroundSize: "cover",
      width: "75vw",
      maxWidth: "500px",
      display: "flex",
      flexDirection:"column",
      alignItems: "center",
      padding: "50px 50px",
      borderRadius:10,
      boxShadow: "0px 5px 20px "+theme.colors.color2ShadeUp[9]
  })
  
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
	return {
    sendActivation: url => dispatch(sendActivation(url)),
    setLoading: val => dispatch(setLoading(val)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Activate);



