import React from 'react'
import { setLoading, unSubscribe } from '../state/reducer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
const Unsubscribe = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const UnSubscribe = () => {
        if (typeof window !== `undefined`) {
            let url = window.location.pathname.split("/unsubscribe/").pop();
            console.log("urlllllll", url)
            dispatch(setLoading({ type: "global" }));

            dispatch(unSubscribe(url, (back) => {
                dispatch(setLoading(false))
                history.push('/success/')
            }))
        }

    }
    return (


        <div className="col-center">
            <div style={{ maxWidth: "100%", margin: "auto", margin: '4rem' }}>
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "center", padding: '2rem'
                }}>
                    <img src={Logo} width={'35%'} height={'100%'} />
                    <br />

                    <p style={{ fontWeight: "bold", color: "#333333", textAlign: "center" }}>Hi, Do you want to unsubscribe from Armbet?</p>
                    <p style={{ textAlign: "center", marginTop: "1px" }}>You will stop receiving messages from about Armbet updates</p>
                    <br />
                    <button onClick={() => UnSubscribe()} style={{
                        cursor: "pointer",
                        backgroundColor: "#e72b2c", color: "white",
                        padding: "1rem 5rem", borderRadius: "7px"

                    }}>Unsubscribe</button>
                </div>
            </div>
        </div>

    )
}

export default Unsubscribe