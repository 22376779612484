export default {
    "_colon_": ":",
    "languages.english": "Inglés",
    "languages.french": "Francés",
    "languages.spanish": "Español",
    "languages.german": "Alemán",
    "languages.hindi": "Hindi",
    "languages.italian": "Italiano",
    "languages.georgian": "Georgiano",
    "languages.portuguese": "Portugués",
    "languages.russian": "Ruso",
    "languages.swedish": "Sueco",
    "languages.turkish": "Turco",
    "languages.ukrainian": "Ucranio",
    "logoAlt": "Logotipo de brazalete",
    "navBar.item1.text": "¿Como funciona?",
    "navBar.item2.text": "Características por venir",
    "navBar.item3.text": "Crear una cuenta",
    "headline": "Bienvenido al mundo de la lucha del brazo",
    "headline.part1": "Bienvenido al mundo de",
    "headline.part2": "pulso",
    "headlineText": "Armbet es una aplicación de lucha de brazos que le permite encontrar socios para formar equipo y ponerse al día, con muchas más características próximamente.",
    "about.title": "Convertirse en miembro",
    "about.text": "Una vez que cree un perfil, se unirá a la comunidad de armwrestling. Podrá encontrar a otros utilizando nuestro mapa, enviarles mensajes, reunirse, entrenar y competir juntos.",
    "features1.title": "Encontrar",
    "features1.text": "Encuentra armwresttlers en tu área o región de interés",
    "features2.title": "Charla",
    "features2.text": "Mensaje a los usuarios para configurar actividades",
    "features3.title": "Entrena y compite",
    "features3.text": "Reúnete para entrenar y competir juntos",
    "comingSoon.title": "Encuentra y crea eventos, únete a las filas!",
    "comingSoon.text": "Pronto podrás encontrar eventos a tu alrededor e incluso crear los tuyos propios. También se ha planeado un sistema de clasificación completo para todos los luchadores de brazo! Y no nos detendremos ahí...",
    "comingSoon.part1": "Encuentra y crea",
    "comingSoon.part2": "eventos",
    "comingSoon.part3":", únete a las",
    "comingSoon.part4":" filas",
    "comingsoon": "Próximamente",
    "signUp.title": "Únete ahora",
    "signUp.text": "Entra en el mundo del armrestling. El deporte más antiguo y accesible. Regístrese ahora y le avisaremos cuando se lance la aplicación.",
    "signUp.part1": "Unirse",
    "signUp.part2": "ahora",
    "signup": "Crear una cuenta",
    "genders.male": "Masculino",
    "genders.female": "Hembra",
    "genders.other": "Otro",
    "signUpSuccess.title": "Gracias por registrarte!",
    "signUpSuccess.text": "Has creado tu cuenta para la aplicación Armbet que viene pronto. Por favor, comprueba tu bandeja de entrada para verificar y activar tu cuenta. Recuerda tus datos de acceso y nos pondremos en contacto contigo cuando la aplicación haya sido lanzada. Nos vemos en el otro lado",
    "password": "Contraseña",
    "email": "Dirección de correo electrónico",
    "fullname": "Nombre completo",
    "fulladdress": "Dirección completa",
    "gender": "Género",
    "createyouraccount": "Crea tu cuenta",
    "changelanguage": "Cambia tu idioma",
    "userterms": "Términos y condiciones del usuario",
    "privacypolicy": "Política de privacidad",
    "copyrightmessage": "© Copyright 2020 Armbet. Todos los derechos reservados.",
    "backtohomepage": "Volver a la página de inicio",
    "message": "Mensaje",
    "close": "Cerca",
    "accountalreadyexists": "Ya existe una cuenta con esa dirección de correo electrónico. Es posible que ya te hayas unido a la comunidad.",
    "notFound.title": "404",
    "notFound.text": "Lo sentimos, pero esa página no se pudo encontrar ...",
    "errorhasoccured": "Lo sentimos, pero ocurrió un error ... Disculpe las molestias.",
    "networkerror": "Lo sentimos pero no pudimos conectarnos al servidor ...",
    "emailcouldntsend": "Lo sentimos, pero podríamos enviar el correo electrónico. Verifique su dirección de correo electrónico e intente nuevamente.",
    "contactus": "Contáctenos",
    "nothanks": "No, gracias",
    "improvetranslationstitle": "¡Ayúdanos a mejorar las traducciones!",
    "improvetranslationstext": "Por favor contáctenos si desea ayudar a mejorar las traducciones",


    "accountactivated": "¡Su cuenta ha sido activada!",
    "accountalreadyactivated": "¡Su cuenta ya ha sido activada!",
    "accountactivatedtext": "Ha creado su cuenta para la aplicación Armbet que está por llegar. Recuerde su correo electrónico y contraseña y nos pondremos en contacto con usted cuando la aplicación haya sido lanzada. Nos vemos en el otro lado.",
    "linknotvalid": "El enlace ha expirado",
    "linknotvalidtext": "El enlace que has introducido no es válido, si quieres activar tu cuenta por favor solicita que se envíe un nuevo correo electrónico a tu bandeja de entrada",

    "privacypolicy.1.text1": "En armbet.com, accesible desde www.armbet.com, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de Política de privacidad contiene tipos de información recopilada y registrada por armbet.com y cómo la usamos.",
    "privacypolicy.1.text2": "Si tiene preguntas adicionales o necesita más información sobre nuestra Política de privacidad, no dude en contactarnos.",
    "privacypolicy.1.text3": "Esta Política de privacidad se aplica solo a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que compartieron y / o recopilaron en armbet.com. Esta política no es aplicable a ninguna información recopilada fuera de línea o por canales que no sean este sitio web.",
    "privacypolicy.2.title": "Consentimiento",
    "privacypolicy.2.text1": "Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y acepta sus términos.",
    "privacypolicy.3.title": "Información que recopilamos",
    "privacypolicy.3.text1": "La información personal que se le solicita que proporcione y los motivos por los que se le solicita que la proporcione, se le aclararán en el momento en que le solicitemos que proporcione su información personal.",
    "privacypolicy.3.text2": "Si nos contacta directamente, podemos recibir información adicional sobre usted, como su nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y / o los archivos adjuntos que puede enviarnos, y cualquier otra información que elija proporcionar.",
    "privacypolicy.3.text3": "Cuando se registra para una Cuenta, podemos solicitar su información de contacto, incluidos elementos como nombre, nombre de la empresa, dirección, dirección de correo electrónico y número de teléfono.",
    "privacypolicy.4.title": "Cómo usamos su información",
    "privacypolicy.4.text1": "Utilizamos la información que recopilamos de varias maneras, que incluyen:",
    "privacypolicy.4.text2": "Proporcionar, operar y mantener nuestro sitio web.",
    "privacypolicy.4.text3": "Mejorar, personalizar y expandir nuestro sitio web",
    "privacypolicy.4.text4": "Comprenda y analice cómo usa nuestro sitio web",
    "privacypolicy.4.text5": "Desarrollar nuevos productos, servicios, características y funcionalidades.",
    "privacypolicy.4.text6": "Comuníquese con usted, ya sea directamente oa través de uno de nuestros socios, incluso para el servicio al cliente, para proporcionarle actualizaciones y otra información relacionada con el sitio web, y con fines de marketing y promoción.",
    "privacypolicy.4.text7": "Enviarle correos electrónicos",
    "privacypolicy.4.text8": "Encuentra y previene el fraude",
    "privacypolicy.5.title": "Archivos de registro",
    "privacypolicy.5.text1": "armbet.com sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan sitios web. Todas las empresas de hosting hacen esto y una parte de los análisis de los servicios de hosting. La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), sello de fecha y hora, páginas de referencia / salida y posiblemente la cantidad de clics. Estos no están vinculados a ninguna información que sea personalmente identificable. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica.",
    "privacypolicy.6.title": "Socios publicitarios Políticas de privacidad",
    "privacypolicy.6.text1": "Puede consultar esta lista para encontrar la Política de privacidad de cada uno de los socios publicitarios de armbet.com.",
    "privacypolicy.6.text2": "Los servidores de anuncios o redes publicitarias de terceros utilizan tecnologías como cookies, JavaScript o balizas web que se utilizan en sus respectivos anuncios y enlaces que aparecen en armbet.com, que se envían directamente al navegador de los usuarios. Reciben automáticamente su dirección IP cuando esto ocurre. Estas tecnologías se utilizan para medir la efectividad de sus campañas publicitarias y / o para personalizar el contenido publicitario que ve en los sitios web que visita.",
    "privacypolicy.6.text3": "Tenga en cuenta que armbet.com no tiene acceso ni control sobre estas cookies que utilizan los anunciantes de terceros.",
    "privacypolicy.7.title": "Políticas de privacidad de terceros",
    "privacypolicy.7.text1": "La Política de privacidad de armbet.com no se aplica a otros anunciantes o sitios web. Por lo tanto, le recomendamos que consulte las políticas de privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo darse de baja de ciertas opciones. Puede encontrar una lista completa de estas Políticas de privacidad y sus enlaces aquí: Enlaces de política de privacidad.",
    "privacypolicy.7.text2": "Puede optar por desactivar las cookies a través de las opciones de su navegador individual. Para conocer información más detallada sobre la gestión de cookies con navegadores web específicos, puede encontrarla en los respectivos sitios web de los navegadores. ¿Qué son las cookies?",
    "privacypolicy.8.title": "Derechos de privacidad de CCPA (No venda mi información personal)",
    "privacypolicy.8.text1": "Según la CCPA, entre otros derechos, los consumidores de California tienen derecho a:",
    "privacypolicy.8.text2": "Solicite que una empresa que recopila los datos personales de un consumidor revele las categorías y datos específicos que una empresa ha recopilado sobre los consumidores.",
    "privacypolicy.8.text3": "Solicite que una empresa elimine cualquier dato personal sobre el consumidor que haya recopilado.",
    "privacypolicy.8.text4": "Solicite que una empresa que vende los datos personales de un consumidor, no venda los datos personales del consumidor.",
    "privacypolicy.8.text5": "Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos, contáctenos.",
    "privacypolicy.9.title": "Derechos de protección de datos de GDPR",
    "privacypolicy.9.text1": "Nos gustaría asegurarnos de que conozca todos sus derechos de protección de datos. Todo usuario tiene derecho a lo siguiente:",
    "privacypolicy.9.text2": "El derecho de acceso: tiene derecho a solicitar copias de sus datos personales. Podemos cobrarle una pequeña tarifa por este servicio.",
    "privacypolicy.9.text3": "El derecho a la rectificación: tiene derecho a solicitar que corrijamos cualquier información que considere inexacta. También tiene derecho a solicitar que completemos la información que considera incompleta.",
    "privacypolicy.9.text4": "El derecho a borrar: tiene derecho a solicitar que borremos sus datos personales, bajo ciertas condiciones.",
    "privacypolicy.9.text5": "El derecho a restringir el procesamiento: tiene derecho a solicitar que restrinjamos el procesamiento de sus datos personales, bajo ciertas condiciones.",
    "privacypolicy.9.text6": "El derecho a oponerse al procesamiento: tiene derecho a oponerse a nuestro procesamiento de sus datos personales, bajo ciertas condiciones.",
    "privacypolicy.9.text7": "El derecho a la portabilidad de datos: tiene derecho a solicitar que transfiramos los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones.",
    "privacypolicy.9.text8": "Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos, contáctenos.",
    "privacypolicy.10.title": "Información para niños",
    "privacypolicy.10.text1": "Otra parte de nuestra prioridad es agregar protección para los niños mientras usan Internet. Alentamos a los padres y tutores a observar, participar y / o monitorear y guiar su actividad en línea.",
    "privacypolicy.10.text2": "armbet.com no recopila a sabiendas ninguna información de identificación personal de niños menores de 13 años. Si cree que su hijo proporcionó este tipo de información en nuestro sitio web, le recomendamos encarecidamente que se ponga en contacto con nosotros de inmediato y haremos nuestro mejor esfuerzo para eliminar rápidamente dicha información de nuestros registros.",
    "user.terms.1.text1": "Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de Armbet, Inc.",
    "user.terms.1.text2": "Armbet, Inc se encuentra en:",
    "user.terms.1.text3": "Leslie St 5863 Unidad 610 North York <br /> M2H 1J8 - Ontario, Canadá",
    "user.terms.1.text4": "Al acceder a este sitio web asumimos que acepta estos términos y condiciones en su totalidad. No continúe usando el sitio web de Armbet, Inc si no acepta todos los términos y condiciones establecidos en esta página.",
    "user.terms.1.text5": "La siguiente terminología se aplica a estos Términos y condiciones, Declaración de privacidad y Aviso de exención de responsabilidad y cualquiera o todos los Acuerdos: 'Cliente', 'Usted' y 'Su' se refiere a usted, la persona que accede a este sitio web y acepta los términos y condiciones de la Compañía. 'La Compañía', 'Nosotros mismos', 'Nosotros', 'Nuestro' y 'Nosotros', se refiere a nuestra Compañía. 'Parte', 'Partes' o 'Nosotros' se refiere tanto al Cliente como a nosotros mismos, o bien al Cliente o a nosotros mismos. Todos los términos se refieren a la oferta, aceptación y consideración del pago necesario para llevar a cabo el proceso de nuestra asistencia al Cliente de la manera más adecuada, ya sea mediante reuniones formales de duración fija o por cualquier otro medio, con el propósito expreso de cumplir con el Las necesidades del cliente con respecto a la provisión de los servicios / productos declarados de la Compañía, de acuerdo con y sujeto a la ley vigente de Canadá. Cualquier uso de la terminología anterior u otras palabras en singular, plural, mayúsculas y / o él / ella o ellos, se consideran intercambiables y, por lo tanto, se refieren a la misma.",
    "user.terms.2.title": "Hipervínculos a nuestro contenido",
    "user.terms.2.text1": "Las siguientes organizaciones pueden enlazar a nuestro sitio web sin previa aprobación por escrito:",
    "user.terms.2.text2": "Agencias gubernamentales;",
    "user.terms.2.text3": "Los motores de búsqueda;",
    "user.terms.2.text4": "Organizaciones de noticias;",
    "user.terms.2.text5": "Los distribuidores de directorios en línea cuando nos enumeran en el directorio pueden vincular a nuestro sitio web de la misma manera que hacen hipervínculos a los sitios web de otros negocios listados;",
    "user.terms.2.text6": "Empresas acreditadas en todo el sistema, excepto solicitar organizaciones sin fines de lucro, centros comerciales de caridad y grupos de recaudación de fondos de caridad que no pueden hacer hipervínculos a nuestro sitio web.",
    "user.terms.2.text7": "Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones u otra información del sitio web siempre que el enlace: (a) no sea de ninguna manera engañoso; (b) no implica falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos o servicios; y (c) se ajusta dentro del contexto del sitio de la parte vinculante.",
    "user.terms.2.text8": "Podemos considerar y aprobar a nuestro exclusivo criterio otras solicitudes de enlace de los siguientes tipos de organizaciones:",
    "user.terms.2.text9": "fuentes de información de consumidores y / o negocios comúnmente conocidas, como las Cámaras de Comercio, la Asociación Americana de Automóviles, AARP y Consumers Union;",
    "user.terms.2.text10": "sitios de la comunidad dot.com;",
    "user.terms.2.text11": "asociaciones u otros grupos que representan organizaciones benéficas, incluidos los sitios de donaciones benéficas,",
    "user.terms.2.text12": "distribuidores de directorios en línea;",
    "user.terms.2.text13": "portales de internet;",
    "user.terms.2.text14": "empresas de contabilidad, derecho y consultoría cuyos clientes principales son empresas; y",
    "user.terms.2.text15": "instituciones educativas y asociaciones comerciales.",
    "user.terms.2.text16": "Aprobaremos solicitudes de enlace de estas organizaciones si determinamos que: (a) el enlace no se reflejaría de manera desfavorable en nosotros o en nuestras empresas acreditadas (por ejemplo, asociaciones comerciales u otras organizaciones que representan tipos de negocios inherentemente sospechosos, como el trabajo en oportunidades de hogar, no se les permitirá vincular); (b) la organización no tiene un registro insatisfactorio con nosotros; (c) el beneficio para nosotros de la visibilidad asociada con el hipervínculo supera la ausencia de Armbet, Inc; y (d) cuando el enlace está en el contexto de información general de recursos o es de otra manera consistente con el contenido editorial en un boletín o producto similar que promueve la misión de la organización.",
    "user.terms.2.text17": "Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones u otra información del sitio web siempre que el enlace: (a) no sea de ninguna manera engañoso; (b) no implica falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos o servicios; y (c) se ajusta dentro del contexto del sitio de la parte vinculante.",
    "user.terms.2.text18": "Si se encuentra entre las organizaciones enumeradas en el párrafo 2 anterior y está interesado en vincularse a nuestro sitio web, debe notificárnoslo enviando un correo electrónico a",
    "user.terms.2.text19": "Incluya su nombre, el nombre de su organización, la información de contacto (como un número de teléfono y / o una dirección de correo electrónico), así como la URL de su sitio, una lista de las URL desde las cuales tiene la intención de vincular a nuestro sitio web, y una lista de las URL en nuestro sitio a las que desea vincular. Permita 2-3 semanas para una respuesta.",
    "user.terms.2.text20": "Las organizaciones aprobadas pueden hacer hipervínculos a nuestro sitio web de la siguiente manera:",
    "user.terms.2.text21": "Mediante el uso de nuestro nombre corporativo; o",
    "user.terms.2.text22": "Mediante el uso del localizador uniforme de recursos (dirección web) al que se vincula; o",
    "user.terms.2.text23": "Mediante el uso de cualquier otra descripción de nuestro sitio web o material vinculado a eso tiene sentido dentro del contexto y el formato del contenido en el sitio de la parte vinculante.",
    "user.terms.2.text24": "No se permitirá el uso del logotipo de Armbet, Inc u otra obra de arte para vincular en ausencia de un acuerdo de licencia de marca registrada.",
    "user.terms.3.title": "Eliminación de enlaces de nuestro sitio web",
    "user.terms.3.text1": "Si encuentra algún enlace en nuestro sitio web o cualquier sitio web vinculado objetable por cualquier motivo, puede comunicarse con nosotros al respecto. Consideraremos solicitudes para eliminar enlaces, pero no tendremos la obligación de hacerlo ni de responderle directamente.",
    "user.terms.3.text2": "Si bien nos esforzamos por garantizar que la información en este sitio web sea correcta, no garantizamos su integridad o precisión; ni nos comprometemos a garantizar que el sitio web permanezca disponible o que el material en el sitio web se mantenga actualizado.",
    "user.terms.4.title": "Responsabilidad de contenido",
    "user.terms.4.text1": "No tendremos ninguna responsabilidad por el contenido que aparezca en su sitio web. Usted acepta indemnizarnos y defendernos contra todos los reclamos que surjan de su sitio web o se basen en él. No puede aparecer ningún enlace en ninguna página de su sitio web ni en ningún contexto que contenga contenido o materiales que puedan interpretarse como difamatorios, obscenos o criminales, o que infrinja, de otra manera viole o defienda la infracción u otra violación de cualquier derechos de terceros.",
    "user.terms.5.title": "Descargo de responsabilidad",
    "user.terms.5.text1": "En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones relacionadas con nuestro sitio web y el uso de este sitio web (incluidas, entre otras, las garantías implícitas por ley con respecto a la calidad satisfactoria, la idoneidad para el propósito y / o el uso de cuidado razonable y habilidad). Nada en este descargo de responsabilidad:",
    "user.terms.5.text2": "limitar o excluir nuestra responsabilidad o la suya por muerte o lesiones personales resultantes de negligencia;",
    "user.terms.5.text3": "limitar o excluir nuestra responsabilidad o la suya por fraude o tergiversación fraudulenta;",
    "user.terms.5.text4": "limitar cualquiera de nuestras responsabilidades o las suyas de cualquier manera que no esté permitida por la ley aplicable; o",
    "user.terms.5.text5": "excluir cualquiera de nuestras responsabilidades o las suyas que no puedan excluirse según la ley aplicable.",
    "user.terms.5.text6": "Las limitaciones y exclusiones de responsabilidad establecidas en esta Sección y en otras partes de este descargo de responsabilidad: (a) están sujetas al párrafo anterior; y (b) regirá todas las responsabilidades derivadas del descargo de responsabilidad o en relación con el tema de este descargo de responsabilidad, incluidas las responsabilidades derivadas del contrato, en agravio (incluida la negligencia) y por incumplimiento del deber legal.",
    "user.terms.5.text7": "En la medida en que el sitio web y la información y servicios en el sitio web se proporcionen de forma gratuita, no seremos responsables de ninguna pérdida o daño de ninguna naturaleza."

}