import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Activate from "./pages/activate";
import ResetPassword from "./pages/resetpassword";
import Style from "./theme/style";
import "./theme/layout.css";
import "./theme/font-faces.css";
import Loading from "./components/Loading";
import MessageBox from "./components/MessageBox";
import Unsubscribe from './pages/unsubscribe';
import UnsubscribeSuccessfully from './pages/successfullUnsubscribe';



function App() {
  return (
    <Router basename='/web/'>
      <Style />
      <Loading />
      <MessageBox />
      <Switch>
        <Route path="/resetpassword/:id">
          <ResetPassword />
        </Route>
        <Route path="/activate/:id/:code">
          <Activate />
        </Route>
        <Route path="/unsubscribe/:id">
          <Unsubscribe />
        </Route>
        <Route path="/success">
          <UnsubscribeSuccessfully />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
