const regexList = {
    telephone: /^(0|(\+([1-9]){2})|00([1-9]){2})[1-9][0-9]{6,14}$/,
    telephoneWithKnownCountryCode: /[0-9][0-9]{6,14}$/,
    fullName: /^[a-zéèâêîôûäëïöüçùà]{2,}((-|\s)[a-zéèâêîôûäëïöüçùà]{2,}){1,4}$/i,
    name: /^[a-zéèâêîôûäëïöüçùà]{2,}((-|\s)[a-zéèâêîôûäëïöüçùà]{2,}){0,4}$/i,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /.{6,}/,
    containsCapital: /^(?=.*[A-Z]).+/,
    containsDigit: /^(?=.*\d).+/,
    containsSpecialCharacter: /(?=.*[!@#$£¥€¨^‰±•¿’”„´≥≤∞…÷≠—¡«¶‘“°%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+/,
    isURL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    isRoundNumUnder100: /^[1-9][0-9]{0,1}$/,
    isRoundNum: /^[1-9][0-9]*$/,
    isPriceUnder100: /^(\d{1,2}(\.\d{1,2}){0,1})$/,
}

export default regexList;

