import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { css } from 'glamor';
import { resetPassword, testUrl, toggleMessageBox } from "../state/reducer";
import trans from "../i18n";
import theme from '../theme';

import Input from "../components/Input";
import Button from "../components/Button";
import Icon from "../components/Icon";
import TextBlock from '../elements/TextBlock/index';


const form = {
    password: {val: "",isValid: -1},
    confirmPassword: {val: "", isValid:-1}
};

class PasswordReset extends Component {
	state = {
		id: "",
		idValidationState: 0,
		passwordValidationState: 0,
        showContent: false,
        showError: false
	};

	componentWillMount() {
		this.testUrl();
	}

	resetPassword = async() => {
		if (this.state.password === this.state.confirmPassword && form.password.isValid === 1 && form.confirmPassword.isValid === 1) {
            console.log("window.location.pathnam : ",window.location.pathname);
            console.log("window.location.pathname.split(sdfdfs).pop() : ",window.location.pathname.split("/resetpassword/").pop());
            try {
                var response = await this.props.onResetPassword(window.location.pathname.split("/resetpassword/").pop(),form.password.val);
            } catch(err) {
                console.log("err : ",err);
            }
            if (response) {
                this.setState({showContent:false,showError:false,showSuccess:true});
            } else {
                this.setState({showContent:false,showError:true,showSuccess:false});
            }
            
        } else {
            console.log("form : ",form);
            if (form.password.isValid !== 1) {
                form.password.isValid = 0;
            }
            if (form.confirmPassword.isValid !== 1) {
                form.confirmPassword.isValid = 0;
            }
            console.log("form after : ",form);
            this.setState({showContent:false,showError:true,showSuccess:false});

        }
		
	};

	testUrl = async() => {
		var url = window.location.pathname.split("/resetpassword/").pop();
		try {
			var response = await this.props.testUrl(url);
		} catch(err) {
            console.log("err : ",err);
		}
		
		if (response) {
			this.setState({showContent:true,showError:false});
		} else {
            this.setState({showContent:false,showError:true});
        }
    }
    
    setFieldValue = (info) => {
        console.log("Getonchange set val : ",info);
        if (info.name) {
            form[info.name].val = info.val;
            form[info.name].isValid = info.isValid;
        }
    }

	render() {
		if (this.state.showContent) {
			return (
                <div className="col-center" style={{paddingTop:"15vh",backgroundColor:"#F4F6F8",width:"100vw",minHeight:"85vh"}}>
                    <div
                        className={styles.box}
                    >
                        <TextBlock 
                            centered
                            titleElement={{text:trans.t("resetpassword"),type:"h3",style:"margin-bottom:0"}} 
                            textElement={{text:trans.t("resetpasswordtext")}} 
                            containerStyle={{marginBottom:40}}
                        ></TextBlock>
                        <Input 
                            getOnChange={this.setFieldValue} 
                            placeholder={trans.t("password")} 
                            containerStyle={{minWidth:"60%"}} 
                            type="password"
                            width={"91%"} 
                            textTransform="capitalize" 
                            required name="password" 
                            contentType="password" 
                            label={trans.t("password")} 
                            vPadding={theme.spacers[2]}  
                        />
                        <Input 
                            getOnChange={this.setFieldValue}
                            placeholder={trans.t("confirmpassword")} 
                            containerStyle={{minWidth:"60%"}} 
                            width={"91%"} 
                            type="password"
                            textTransform="capitalize" 
                            required 
                            name="confirmPassword" 
                            contentType="password" 
                            label={trans.t("confirmpassword")} 
                            vPadding={theme.spacers[2]} 
                            customValidation={(val) => {if(val === form.password.val) return 1; return 0}}
                        />
                        <Button
                            type="button"
                            onClick={this.resetPassword}
                            style={{marginTop:30}}
                        >
                            Confirmer
                        </Button>
                    </div>
                    
                </div>
			);
		} else if (this.state.showError || this.state.showSuccess) {
            return (
                <div className="col-center" style={{paddingTop:"15vh",backgroundColor:"#F4F6F8",width:"100vw",minHeight:"85vh"}}>
                    <div
                        className={styles.box}
                    >   
                        <TextBlock 
                                centered
                                titleElement={{text:this.state.showSuccess ? trans.t("resetpasswordsuccess") : trans.t("resetpassworderror"),type:"h3"}} 
                                textElement={{text:this.state.showSuccess ? trans.t("resetpasswordsuccesstext") : trans.t("resetpassworderrortext") }}
                        ></TextBlock>
                    </div>
                </div>
            )
        }
		return (
            <div className="col-center-center" style={{backgroundColor:"#F4F6F8",width:"100vw",minHeight:"100vh"}}>
                <Icon iconStyle={{height:"50px"}} icon={theme.inlineLoader} /> 
            </div>
        )

		
	}
}

const styles = {
    box: css({
        zIndex: 2,
        backgroundColor: "white",
        backgroundSize: "cover",
        width: "80vw",
        maxWidth: "500px",
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        padding: "50px 30px",
        borderRadius:10,
        boxShadow: "0px 5px 20px "+theme.colors.color2ShadeUp[9]
    })
    
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
	return {
		testUrl: url => dispatch(testUrl(url)),
        onResetPassword: (url, password) => dispatch(resetPassword(url, password)),
        toggleMessageBox: (val) => dispatch(toggleMessageBox(val))
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
);
