export default {
    "_colon_": "::",
    "languages.english": "Englisch",
    "languages.french": "Französisch",
    "languages.spanish": "Spanisch",
    "languages.german": "Deutsche",
    "languages.hindi": "Hindi",
    "languages.italian": "Italienisch",
    "languages.georgian": "Georgisch",
    "languages.portuguese": "Portugiesisch",
    "languages.russian": "Russisch",
    "languages.swedish": "Schwedisch",
    "languages.turkish": "Türkisch",
    "languages.ukrainian": "Ukrainisch",
    "logoAlt": "Armbet Logo",
    "navBar.item1.text": "Wie funktioniert es?",
    "navBar.item2.text": "Features zu kommen",
    "navBar.item3.text": "Benutzerkonto anlegen",
    "headline": "Willkommen in der Welt des Armdrücken",
    "headline.part1": "Willkommen in der Welt von",
    "headline.part2": "Armdrücken",
    "headlineText": "Armbet ist eine Armwrestling-App, mit der Sie Partner finden können, die sich zusammenschließen und mit ihnen zusammenarbeiten können. Viele weitere Funktionen werden in Kürze verfügbar sein.",
    "about.title": "Mitglied werden",
    "about.text": "Sobald Sie ein Profil erstellt haben, treten Sie der Armwrestling-Community bei. Mithilfe unserer Karte können Sie andere finden, ihnen eine Nachricht senden, sich treffen, trainieren und gemeinsam an Wettkämpfen teilnehmen.",
    "features1.title": "Finden",
    "features1.text": "Finden Sie Armwrestler in Ihrer Nähe oder Region von Interesse",
    "features2.title": "Plaudern",
    "features2.text": "Nachrichtenbenutzer zum Einrichten von Aktivitäten",
    "features3.title": "Trainiere und trete an",
    "features3.text": "Treffen Sie sich, um gemeinsam zu trainieren und an Wettkämpfen teilzunehmen",
    "comingSoon.title": "Finden und schaffen Sie Veranstaltungen, schließen Sie sich an!",
    "comingSoon.text": "Bald können Sie Veranstaltungen um sich herum finden und sogar Ihre eigenen erstellen. Ein komplettes Rankingsystem für alle Armdrücker ist ebenfalls geplant! Und damit werden wir nicht aufhören...",
    "comingSoon.part1": "Finden und erstellen",
    "comingSoon.part2": "Veranstaltungen",
    "comingSoon.part3":", schließen Sie sich der",
    "comingSoon.part4":" rangiert",
    "comingsoon": "Kommt bald",
    "signUp.title": "Jetzt beitreten",
    "signUp.text": "Betreten Sie die Welt des Armwrestlings. Der älteste und zugänglichste Sport. Melden Sie sich jetzt an und wir werden Sie informieren, wenn die App veröffentlicht wurde.",
    "signUp.part1": "Beitreten",
    "signUp.part2": "jetzt",
    "signup": "Benutzerkonto anlegen",
    "genders.male": "Männlich",
    "genders.female": "Weiblich",
    "genders.other": "Andere",
    "signUpSuccess.title": "Danke fürs Anmelden!",
    "signUpSuccess.text": "Sie haben Ihr Konto für die Armbet-App, die bald erscheinen wird, erstellt. Bitte überprüfen Sie Ihren Posteingang, um Ihr Konto zu verifizieren und zu aktivieren. Merken Sie sich Ihre Anmeldedaten und wir werden Sie kontaktieren, wenn die App veröffentlicht wurde. Wir sehen uns auf der anderen Seite.",
    "password": "Passwort",
    "email": "E-Mail-Addresse",
    "fullname": "Vollständiger Name",
    "fulladdress": "Vollständige Adresse",
    "gender": "Geschlecht",
    "createyouraccount": "Erstelle deinen Account",
    "changelanguage": "Ändere deine Sprache",
    "userterms": "Nutzungsbedingungen",
    "privacypolicy": "Datenschutz-Bestimmungen",
    "copyrightmessage": "© Copyright 2020 Armbet. Alle Rechte vorbehalten.",
    "backtohomepage": "Zurück zur Startseite",
    "message": "Botschaft",
    "close": "Schließen",
    "accountalreadyexists": "Ein Konto mit dieser E-Mail-Adresse ist bereits vorhanden. Möglicherweise sind Sie bereits der Community beigetreten.",
    "notFound.title": "404",
    "notFound.text": "Es tut uns leid, aber diese Seite konnte nicht gefunden werden ...",
    "errorhasoccured": "Es tut uns leid, aber es ist ein Fehler aufgetreten ... Entschuldigen Sie die Unannehmlichkeiten.",
    "networkerror": "Es tut uns leid, aber wir konnten keine Verbindung zum Server herstellen ...",
    "emailcouldntsend": "Es tut uns leid, aber wir könnten die E-Mail senden. Bitte überprüfen Sie Ihre E-Mail-Adresse und versuchen Sie es erneut.",
    "contactus": "Kontaktiere uns",
    "nothanks": "Nein Danke",
    "improvetranslationstitle": "Helfen Sie uns, die Übersetzungen zu verbessern!",
    "improvetranslationstext": "Bitte kontaktieren Sie uns, wenn Sie zur Verbesserung der Übersetzungen beitragen möchten",

    "accountactivated": "Ihr Konto wurde aktiviert!",
    "accountalreadyactivated": "Ihr Konto wurde bereits aktiviert!",
    "accountactivatedtext": "Sie haben Ihr Konto für die bald erscheinende Armbet-App erstellt.  Merken Sie sich Ihre E-Mail und Ihr Passwort und wir werden Sie kontaktieren, wenn die App veröffentlicht wurde.  Wir sehen uns auf der anderen Seite.",
    "linknotvalid": "Der Link ist abgelaufen",
    "linknotvalidtext": "Der von Ihnen eingegebene Link ist nicht gültig. Wenn Sie Ihr Konto aktivieren möchten, fordern Sie bitte eine neue E-Mail in Ihrem Posteingang an.",

    "privacypolicy.1.text1": "Bei armbet.com, das über www.armbet.com zugänglich ist, ist die Privatsphäre unserer Besucher eine unserer Hauptprioritäten. Dieses Datenschutzdokument enthält Arten von Informationen, die von armbet.com gesammelt und aufgezeichnet werden, und wie wir sie verwenden.",
    "privacypolicy.1.text2": "Wenn Sie weitere Fragen haben oder weitere Informationen zu unserer Datenschutzrichtlinie benötigen, zögern Sie nicht, uns zu kontaktieren.",
    "privacypolicy.1.text3": "Diese Datenschutzrichtlinie gilt nur für unsere Online-Aktivitäten und gilt für Besucher unserer Website in Bezug auf die Informationen, die sie auf armbet.com geteilt und / oder gesammelt haben. Diese Richtlinie gilt nicht für Informationen, die offline oder über andere Kanäle als diese Website gesammelt werden.",
    "privacypolicy.2.title": "Zustimmung",
    "privacypolicy.2.text1": "Durch die Nutzung unserer Website stimmen Sie hiermit unserer Datenschutzrichtlinie zu und stimmen deren Bedingungen zu.",
    "privacypolicy.3.title": "Informationen, die wir sammeln",
    "privacypolicy.3.text1": "Die von Ihnen angeforderten persönlichen Daten und die Gründe, warum Sie zur Angabe aufgefordert werden, werden Ihnen an dem Punkt klargestellt, an dem wir Sie auffordern, Ihre persönlichen Daten anzugeben.",
    "privacypolicy.3.text2": "Wenn Sie uns direkt kontaktieren, erhalten wir möglicherweise zusätzliche Informationen über Sie, wie z. B. Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer, den Inhalt der Nachricht und / oder der Anhänge, die Sie uns senden, sowie weitere Informationen, die Sie möglicherweise angeben.",
    "privacypolicy.3.text3": "Wenn Sie sich für ein Konto registrieren, werden Sie möglicherweise nach Ihren Kontaktinformationen gefragt, einschließlich Namen, Firmenname, Adresse, E-Mail-Adresse und Telefonnummer.",
    "privacypolicy.4.title": "Wie wir Ihre Daten verwenden",
    "privacypolicy.4.text1": "Wir verwenden die von uns gesammelten Informationen auf verschiedene Weise, unter anderem um:",
    "privacypolicy.4.text2": "Bereitstellung, Betrieb und Wartung unserer Website",
    "privacypolicy.4.text3": "Verbessern, personalisieren und erweitern Sie unser Webste",
    "privacypolicy.4.text4": "Verstehen und analysieren Sie, wie Sie unser Webste nutzen",
    "privacypolicy.4.text5": "Entwickeln Sie neue Produkte, Dienstleistungen, Features und Funktionen",
    "privacypolicy.4.text6": "Kommunizieren Sie direkt oder über einen unserer Partner mit Ihnen, auch für den Kundenservice, um Sie mit Updates und anderen Informationen in Bezug auf das Webste sowie für Marketing- und Werbezwecke zu versorgen",
    "privacypolicy.4.text7": "Senden Sie Ihnen E-Mails",
    "privacypolicy.4.text8": "Betrug finden und verhindern",
    "privacypolicy.5.title": "Protokolldateien",
    "privacypolicy.5.text1": "armbet.com folgt einem Standardverfahren zur Verwendung von Protokolldateien. Diese Dateien protokollieren Besucher, wenn sie Websites besuchen. Alle Hosting-Unternehmen tun dies und sind Teil der Analyse der Hosting-Services. Zu den von Protokolldateien gesammelten Informationen gehören IP-Adressen (Internet Protocol), Browsertyp, Internet Service Provider (ISP), Datums- und Zeitstempel, Verweis- / Beendigungsseiten und möglicherweise die Anzahl der Klicks. Diese sind nicht mit Informationen verknüpft, die persönlich identifizierbar sind. Der Zweck der Informationen besteht darin, Trends zu analysieren, die Website zu verwalten, die Bewegung der Benutzer auf der Website zu verfolgen und demografische Informationen zu sammeln.",
    "privacypolicy.6.title": "Datenschutzrichtlinien für Werbepartner",
    "privacypolicy.6.text1": "In dieser Liste finden Sie die Datenschutzbestimmungen für jeden Werbepartner von armbet.com.",
    "privacypolicy.6.text2": "Ad-Server oder Ad-Netzwerke von Drittanbietern verwenden Technologien wie Cookies, JavaScript oder Web Beacons, die in ihren jeweiligen Anzeigen und Links auf armbet.com verwendet werden und direkt an den Browser der Benutzer gesendet werden. In diesem Fall erhalten sie automatisch Ihre IP-Adresse. Diese Technologien werden verwendet, um die Effektivität ihrer Werbekampagnen zu messen und / oder um den Werbeinhalt zu personalisieren, den Sie auf den von Ihnen besuchten Websites sehen.",
    "privacypolicy.6.text3": "Beachten Sie, dass armbet.com keinen Zugriff auf oder keine Kontrolle über diese Cookies hat, die von Werbekunden Dritter verwendet werden.",
    "privacypolicy.7.title": "Datenschutzrichtlinien von Drittanbietern",
    "privacypolicy.7.text1": "Die Datenschutzrichtlinie von armbet.com gilt nicht für andere Werbetreibende oder Websites. Wir empfehlen Ihnen daher, die jeweiligen Datenschutzrichtlinien dieser Ad-Server von Drittanbietern zu konsultieren, um detailliertere Informationen zu erhalten. Es kann ihre Praktiken und Anweisungen zum Deaktivieren bestimmter Optionen enthalten. Eine vollständige Liste dieser Datenschutzrichtlinien und ihrer Links finden Sie hier: Links zu Datenschutzrichtlinien.",
    "privacypolicy.7.text2": "Sie können Cookies über Ihre individuellen Browseroptionen deaktivieren. Weitere Informationen zur Cookie-Verwaltung mit bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der Browser. Was sind Cookies?",
    "privacypolicy.8.title": "CCPA-Datenschutzrechte (Verkaufen Sie meine persönlichen Daten nicht)",
    "privacypolicy.8.text1": "Im Rahmen der CCPA haben kalifornische Verbraucher unter anderem das Recht:",
    "privacypolicy.8.text2": "Fordern Sie ein Unternehmen auf, die personenbezogenen Daten eines Verbrauchers erfasst, die Kategorien und spezifischen personenbezogenen Daten offenzulegen, die ein Unternehmen über Verbraucher erfasst hat.",
    "privacypolicy.8.text3": "Fordern Sie ein Unternehmen auf, alle persönlichen Daten über den Verbraucher zu löschen, die ein Unternehmen gesammelt hat.",
    "privacypolicy.8.text4": "Fordern Sie ein Unternehmen auf, die personenbezogenen Daten eines Verbrauchers verkauft und nicht die personenbezogenen Daten des Verbrauchers.",
    "privacypolicy.8.text5": "Wenn Sie eine Anfrage stellen, haben wir einen Monat Zeit, um Ihnen zu antworten. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte.",
    "privacypolicy.9.title": "Datenschutzrechte der DSGVO",
    "privacypolicy.9.text1": "Wir möchten sicherstellen, dass Sie alle Ihre Datenschutzrechte vollständig kennen. Jeder Benutzer hat Anspruch auf Folgendes:",
    "privacypolicy.9.text2": "Zugriffsrecht - Sie haben das Recht, Kopien Ihrer persönlichen Daten anzufordern. Wir können Ihnen eine geringe Gebühr für diesen Service berechnen.",
    "privacypolicy.9.text3": "Das Recht auf Berichtigung - Sie haben das Recht zu verlangen, dass wir alle Informationen korrigieren, die Sie für ungenau halten. Sie haben auch das Recht zu verlangen, dass wir die Informationen vervollständigen, die Sie für unvollständig halten.",
    "privacypolicy.9.text4": "Das Recht auf Löschung - Sie haben das Recht, unter bestimmten Bedingungen zu verlangen, dass wir Ihre persönlichen Daten löschen.",
    "privacypolicy.9.text5": "Das Recht, die Verarbeitung einzuschränken - Sie haben das Recht, zu verlangen, dass wir die Verarbeitung Ihrer persönlichen Daten unter bestimmten Bedingungen einschränken.",
    "privacypolicy.9.text6": "Widerspruchsrecht - Sie haben unter bestimmten Bedingungen das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen.",
    "privacypolicy.9.text7": "Das Recht auf Datenübertragbarkeit - Sie haben das Recht zu verlangen, dass wir die von uns gesammelten Daten unter bestimmten Bedingungen an eine andere Organisation oder direkt an Sie übertragen.",
    "privacypolicy.9.text8": "Wenn Sie eine Anfrage stellen, haben wir einen Monat Zeit, um Ihnen zu antworten. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte.",
    "privacypolicy.10.title": "Informationen für Kinder",
    "privacypolicy.10.text1": "Ein weiterer Teil unserer Priorität ist der Schutz von Kindern bei der Nutzung des Internets. Wir ermutigen Eltern und Erziehungsberechtigte, ihre Online-Aktivitäten zu beobachten, daran teilzunehmen und / oder zu überwachen und zu leiten.",
    "privacypolicy.10.text2": "armbet.com sammelt wissentlich keine personenbezogenen Daten von Kindern unter 13 Jahren. Wenn Sie der Meinung sind, dass Ihr Kind diese Art von Informationen auf unserer Website bereitgestellt hat, empfehlen wir Ihnen dringend, sich umgehend mit uns in Verbindung zu setzen, und wir werden unser Bestes tun, um dies zu tun Entfernen Sie diese Informationen umgehend aus unseren Unterlagen.",
    "user.terms.1.text1": "Diese Allgemeinen Geschäftsbedingungen enthalten die Regeln und Vorschriften für die Nutzung der Website von Armbet, Inc.",
    "user.terms.1.text2": "Armbet, Inc befindet sich in:",
    "user.terms.1.text3": "Leslie St 5863 Unit 610 North York <br /> M2H 1J8 - Ontario, Kanada",
    "user.terms.1.text4": "Durch den Zugriff auf diese Website gehen wir davon aus, dass Sie diese Allgemeinen Geschäftsbedingungen vollständig akzeptieren. Verwenden Sie die Website von Armbet, Inc nicht weiter, wenn Sie nicht alle auf dieser Seite aufgeführten Bedingungen akzeptieren.",
    "user.terms.1.text5": "Die folgende Terminologie gilt für diese Allgemeinen Geschäftsbedingungen, die Datenschutzerklärung und den Haftungsausschluss sowie für einige oder alle Vereinbarungen: 'Kunde', 'Sie' und 'Ihr' beziehen sich auf Sie, die Person, die auf diese Website zugreift und die Allgemeinen Geschäftsbedingungen des Unternehmens akzeptiert. 'Das Unternehmen', 'Wir selbst', 'Wir', 'Unser' und 'Wir' beziehen sich auf unser Unternehmen. 'Partei', 'Parteien' oder 'Wir' bezieht sich sowohl auf den Kunden als auch auf uns selbst oder entweder auf den Kunden oder auf uns selbst. Alle Bedingungen beziehen sich auf das Angebot, die Annahme und die Gegenleistung für die Zahlung, die erforderlich sind, um den Prozess unserer Unterstützung für den Kunden in angemessener Weise durchzuführen, sei es durch formelle Sitzungen von fester Dauer oder auf andere Weise, zum ausdrücklichen Zweck der Erfüllung der Kundenbedürfnisse in Bezug auf die Bereitstellung der angegebenen Dienstleistungen / Produkte des Unternehmens in Übereinstimmung mit und vorbehaltlich des geltenden kanadischen Rechts. Jede Verwendung der obigen Terminologie oder anderer Wörter im Singular, Plural, Groß- und Kleinschreibung und / oder er / sie oder sie wird als austauschbar angesehen und bezieht sich daher auf diese.",
    "user.terms.2.title": "Hyperlink zu unseren Inhalten",
    "user.terms.2.text1": "Die folgenden Organisationen können ohne vorherige schriftliche Genehmigung auf unsere Website verlinken:",
    "user.terms.2.text2": "Regierungsbehörden;",
    "user.terms.2.text3": "Suchmaschinen;",
    "user.terms.2.text4": "Nachrichtenorganisationen;",
    "user.terms.2.text5": "Online-Verzeichnisverteiler können, wenn sie uns im Verzeichnis auflisten, auf dieselbe Weise auf unsere Website verlinken wie auf Websites anderer gelisteter Unternehmen.",
    "user.terms.2.text6": "Systemweit akkreditierte Unternehmen, mit Ausnahme der Anwerbung von gemeinnützigen Organisationen, Einkaufszentren für Wohltätigkeitszwecke und Spendengruppen für Wohltätigkeitszwecke, die möglicherweise keinen Hyperlink zu unserer Website enthalten.",
    "user.terms.2.text7": "Diese Organisationen dürfen Links zu unserer Homepage, zu Veröffentlichungen oder zu anderen Website-Informationen erstellen, sofern der Link: (a) in keiner Weise irreführend ist; (b) bedeutet nicht fälschlicherweise Sponsoring, Billigung oder Genehmigung der Verbindungspartei und ihrer Produkte oder Dienstleistungen; und (c) in den Kontext der Website der Linkpartei passt.",
    "user.terms.2.text8": "Wir können andere Linkanfragen von folgenden Arten von Organisationen nach eigenem Ermessen prüfen und genehmigen:",
    "user.terms.2.text9": "allgemein bekannte Verbraucher- und / oder Geschäftsinformationsquellen wie Handelskammern, American Automobile Association, AARP und Consumers Union;",
    "user.terms.2.text10": "dot.com Community-Websites;",
    "user.terms.2.text11": "Vereinigungen oder andere Gruppen, die Wohltätigkeitsorganisationen vertreten, einschließlich Wohltätigkeitsorganisationen,",
    "user.terms.2.text12": "Online-Verzeichnisverteiler;",
    "user.terms.2.text13": "Internetportale;",
    "user.terms.2.text14": "Wirtschaftsprüfungs-, Rechts- und Beratungsunternehmen, deren Hauptkunden Unternehmen sind; und",
    "user.terms.2.text15": "Bildungseinrichtungen und Fachverbände.",
    "user.terms.2.text16": "Wir werden Linkanfragen von diesen Organisationen genehmigen, wenn wir feststellen, dass: (a) der Link uns oder unsere akkreditierten Unternehmen (z. B. Handelsverbände oder andere Organisationen, die von Natur aus verdächtige Geschäftsarten vertreten, wie z. Heimgelegenheiten, dürfen nicht verlinken); (b) die Organisation hat keine unbefriedigenden Aufzeichnungen bei uns; (c) der Nutzen der mit dem Hyperlink verbundenen Sichtbarkeit für uns überwiegt das Fehlen von Armbet, Inc; und (d) wenn der Link im Kontext allgemeiner Ressourceninformationen steht oder auf andere Weise mit dem redaktionellen Inhalt eines Newsletters oder eines ähnlichen Produkts übereinstimmt, das die Mission der Organisation fördert.",
    "user.terms.2.text17": "Diese Organisationen dürfen Links zu unserer Homepage, zu Veröffentlichungen oder zu anderen Website-Informationen erstellen, sofern der Link: (a) in keiner Weise irreführend ist; (b) bedeutet nicht fälschlicherweise Sponsoring, Billigung oder Genehmigung der verbindenden Partei und ihrer Produkte oder Dienstleistungen; und (c) in den Kontext der Website der Linkpartei passt.",
    "user.terms.2.text18": "Wenn Sie zu den in Absatz 2 aufgeführten Organisationen gehören und auf unsere Website verlinken möchten, müssen Sie uns dies per E-Mail an mitteilen",
    "user.terms.2.text19": "Bitte geben Sie Ihren Namen, den Namen Ihrer Organisation, Kontaktinformationen (wie eine Telefonnummer und / oder eine E-Mail-Adresse) sowie die URL Ihrer Website, eine Liste aller URLs an, von denen Sie auf unsere Website verlinken möchten. und eine Liste der URLs auf unserer Website, auf die Sie verlinken möchten. Warten Sie 2-3 Wochen, bis Sie eine Antwort erhalten.",
    "user.terms.2.text20": "Zugelassene Organisationen können wie folgt auf unsere Website verlinken:",
    "user.terms.2.text21": "Unter Verwendung unseres Firmennamens; oder",
    "user.terms.2.text22": "Durch Verwendung des einheitlichen Ressourcen-Locators (Webadresse), mit dem verknüpft wird; oder",
    "user.terms.2.text23": "Durch die Verwendung einer anderen Beschreibung unserer Website oder des verlinkten Materials ist dies im Kontext und Format des Inhalts auf der Website der verknüpften Partei sinnvoll.",
    "user.terms.2.text24": "Die Verwendung des Logos von Armbet, Inc oder anderer Grafiken zum Verknüpfen ist ohne eine Markenlizenzvereinbarung nicht gestattet.",
    "user.terms.3.title": "Entfernung von Links von unserer Website",
    "user.terms.3.text1": "Wenn Sie einen Link auf unserer Website oder eine verlinkte Website aus irgendeinem Grund als unzulässig empfinden, können Sie uns diesbezüglich kontaktieren. Wir werden Anfragen zum Entfernen von Links prüfen, sind jedoch nicht verpflichtet, dies zu tun oder direkt auf Sie zu antworten.",
    "user.terms.3.text2": "Obwohl wir uns bemühen, sicherzustellen, dass die Informationen auf dieser Website korrekt sind, übernehmen wir keine Gewähr für deren Vollständigkeit oder Richtigkeit. Wir verpflichten uns auch nicht, sicherzustellen, dass die Website verfügbar bleibt oder dass das Material auf der Website auf dem neuesten Stand gehalten wird.",
    "user.terms.4.title": "Inhaltshaftung",
    "user.terms.4.text1": "Wir übernehmen keine Verantwortung oder Haftung für Inhalte, die auf Ihrer Website erscheinen. Sie erklären sich damit einverstanden, uns von allen Ansprüchen aus oder aufgrund Ihrer Website freizustellen und zu verteidigen. Es dürfen keine Links auf einer Seite Ihrer Website oder in einem Kontext erscheinen, der Inhalte oder Materialien enthält, die als verleumderisch, obszön oder kriminell interpretiert werden können oder die gegen eine andere Verletzung verstoßen, diese anderweitig verletzen oder befürworten Rechte Dritter.",
    "user.terms.5.title": "Haftungsausschluss",
    "user.terms.5.text1": "Soweit gesetzlich zulässig, schließen wir alle Zusicherungen, Gewährleistungen und Bedingungen in Bezug auf unsere Website und die Nutzung dieser Website aus (einschließlich, ohne Einschränkung, aller gesetzlich vorgeschriebenen Gewährleistungen in Bezug auf zufriedenstellende Qualität, Gebrauchstauglichkeit und / oder den Einsatz angemessener Sorgfalt und Fähigkeiten). Nichts in diesem Haftungsausschluss wird:",
    "user.terms.5.text2": "unsere oder Ihre Haftung für Tod oder Körperverletzung aufgrund von Fahrlässigkeit einschränken oder ausschließen;",
    "user.terms.5.text3": "unsere oder Ihre Haftung für Betrug oder betrügerische Falschdarstellung einschränken oder ausschließen;",
    "user.terms.5.text4": "eine unserer oder Ihrer Verbindlichkeiten auf eine Weise einschränken, die nach geltendem Recht nicht zulässig ist; oder",
    "user.terms.5.text5": "Schließen Sie eine unserer oder Ihrer Verbindlichkeiten aus, die nach geltendem Recht möglicherweise nicht ausgeschlossen sind.",
    "user.terms.5.text6": "Die in diesem Abschnitt und an anderer Stelle in diesem Haftungsausschluss aufgeführten Haftungsbeschränkungen und -ausschlüsse: (a) unterliegen dem vorhergehenden Absatz; und (b) alle Verbindlichkeiten regeln, die sich aus dem Haftungsausschluss oder in Bezug auf den Gegenstand dieses Haftungsausschlusses ergeben, einschließlich vertraglicher Verpflichtungen aus unerlaubter Handlung (einschließlich Fahrlässigkeit) und wegen Verletzung gesetzlicher Pflichten.",
    "user.terms.5.text7": "Soweit die Website sowie die Informationen und Dienstleistungen auf der Website kostenlos zur Verfügung gestellt werden, haften wir nicht für Verluste oder Schäden jeglicher Art."

}