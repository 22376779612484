
import axios from 'axios';
import c from "../../resources/globals";
import f from "../../resources/functions";
import trans from "../../i18n";
import theme from '../../theme';

const INIT_STATE = {
    loading: {
        type: null
    },
    showMessage: false,
    showSideDialog: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "LOADING":
            return { ...state, loading: action.payload };
        case "SHOW_MESSAGE":
            return { ...state, showMessage: action.payload };
        default:
            return state;
    }
}


export const manageErrors = (errors) => async dispatch => {
    errors.forEach(error => {
        if (error.type === "ACCOUNT_EXISTS") {
            dispatch({ type: "SHOW_MESSAGE", payload: trans.t("accountalreadyexists") });
        } else if (error.type === "NETWORK_ERROR") {
            dispatch({ type: "SHOW_MESSAGE", payload: trans.t("networkerror") });
        } else if (error.type === "EMAIL_COULDNT_SEND") {
            dispatch({ type: "SHOW_MESSAGE", payload: trans.t("emailcouldntsend") });
        } else {
            dispatch({ type: "SHOW_MESSAGE", payload: trans.t("errorhasoccured") });
        }
    });
}

export const sendActivation = (url) => async (dispatch) => {
    console.log("url :", url);
    try {
        var response = await axios.get(c.apiString + "user/activate/" + url.substr(0, url.indexOf('/')) + "/" + url.split("/").pop());
    } catch (err) {
        console.log("err : ", err);
        return false;
    }
    console.log("response : ", response);
    console.log("response.data : ", response.data);
    return response.data;
};



export const unSubscribe = (url, cb) => async (dispatch) => {
    console.log("url :", url);
    try {

        var response = await axios.get(c.apiString + 'user/unsubscribe/' + url.substr(0, url.indexOf('/')) + url.split("/").pop());
        cb && cb()
    } catch (err) {
        dispatch(setLoading(false))
        console.log("err : ", err);
        return false;
    }
    console.log("response : ", response);
    console.log("response.data : ", response.data);
    return response.data;
};

export const resetPassword = (url, password) => async (dispatch) => {
    dispatch({ type: "LOADING", payload: { type: "global" } });
    try {
        var response = await axios.post(c.apiString, {
            query: `mutation {
				resetPassword(url:"${url}",password:"${password}")
			}`
        });
    } catch (err) {
        dispatch({ type: "LOADING", payload: { type: null } });
        console.log("err : ", err);

        return;
    }
    dispatch({ type: "LOADING", payload: { type: null } });
    console.log("response : ", response);

    if (response.data.errors) {

    } else {

        return response.data.data.resetPassword;
    }
    console.log("response.data.data.resetPassword : ", response.data.data.resetPassword);

};

export const testUrl = (url) => async (dispatch) => {
    try {
        var response = await axios.post(c.apiString, {
            query: `{
                testResetPasswordUrl(url:"${url}")
            }`
        });
    } catch (err) {
        console.log("err : ", err);
        return false;
    }
    console.log("response : ", response);

    if (response.data.errors) {
        return false;
    } else {
        return response.data.data.testResetPasswordUrl;
    }
};

export function setLoading(val) {
    return {
        type: "LOADING",
        payload: val
    };
}

export const setLanguage = (lang, dontChange) => async dispatch => {
    console.log("da lang : ", lang);
    if (lang && !theme.wellTranslatedLanguages.includes(lang)) {
        dispatch({
            type: "SHOW_SIDE_DIALOG",
            payload: { title: "improvetranslationstitle", text: "improvetranslationstext", href1: "mailto:support@armbet.com" }
        });
    } else {
        dispatch({
            type: "SHOW_SIDE_DIALOG",
            payload: false
        });
    }
    if (!dontChange) {
        f.changeLanguage(lang);
    }
}



export function toggleMessageBox(val) {
    return {
        type: "SHOW_MESSAGE",
        payload: val
    };
}




/*import { combineReducers } from "redux";

import main from "./main";

const AppReducer = combineReducers({
    main
});

const rootReducer = (state, action) => {

    if (action.type === 'EMPTY_STATE') {
        state = null
    }
    return AppReducer(state, action)
}

export default rootReducer;
*/
