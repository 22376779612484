export default {
    "_colon_": ":",
    "languages.english": "Англійська",
    "languages.french": "Французький",
    "languages.spanish": "Іспанська",
    "languages.german": "Німецька",
    "languages.hindi": "Хінді",
    "languages.italian": "Італійська",
    "languages.georgian": "Грузинська",
    "languages.portuguese": "Португальська",
    "languages.russian": "російський",
    "languages.swedish": "Шведська",
    "languages.turkish": "Турецька",
    "languages.ukrainian": "Українська",
    "logoAlt": "Armbet логотип",
    "navBar.item1.text": "Як це працює?",
    "navBar.item2.text": "Особливості в майбутньому",
    "navBar.item3.text": "Створити рахунок",
    "headline": "Ласкаво просимо у світ армрестлінгу",
    "headline.part1": "Ласкаво просимо у світ с",
    "headline.part2": "армрестлінг",
    "headlineText": "Armbet - це програма боротьби з армрестлінгами, яка дозволяє вам знайти партнерів, щоб згрупуватися та зчепитись, а незабаром з’явиться ще багато функцій.",
    "about.title": "Стати членом",
    "about.text": "Після створення профілю ви приєднаєтесь до спільноти армрестлінгу. Ви зможете знайти інших за допомогою нашої карти, надіслати їм повідомлення, зустрітися та тренуватися та змагатись разом.",
    "features1.title": "Знайдіть",
    "features1.text": "Знайдіть армрестлерів у вашому районі чи регіоні, що цікавить вас",
    "features2.title": "Чат",
    "features2.text": "Повідомлення користувачів для налаштування діяльності",
    "features3.title": "Тренуйся і змагайся",
    "features3.text": "Зустріньтесь, щоб тренуватися та змагатися разом",
    "comingSoon.title": "Знайдіть і створюйте події, приєднуйтесь до лав!",
    "comingSoon.text": "Незабаром ви зможете знайти події навколо себе і навіть створити свою власну. Також запланована повна система рейтингу для всіх борців за зброю! І ми на цьому не зупинимося ...",
    "comingSoon.part1": "Знайдіть і створіть",
    "comingSoon.part2": "події",
    "comingSoon.part3":", приєднуйтесь до",
    "comingSoon.part4":" лав",
    "comingsoon": "Незабаром",
    "signUp.title": "Приєднуйся зараз",
    "signUp.text": "Увійдіть у світ армрестлінгу. Найдавніший і доступний вид спорту. Зареєструйтесь зараз, і ми повідомимо вас про те, коли додаток буде випущено.",
    "signUp.part1": "Приєднуйтесь",
    "signUp.part2": "зараз",
    "signup": "Створити рахунок",
    "genders.male": "Чоловік",
    "genders.female": "Жіночий",
    "genders.other": "Інший",
    "signUpSuccess.title": "Дякуємо, що зареєструвались!",
    "signUpSuccess.text": "Ви створили свій обліковий запис для програми Armbet, яка незабаром з'явиться. Будь ласка, перевірте свою скриньку вхідних, щоб підтвердити та активувати ваш рахунок. Запам’ятайте свої дані про вхід, і ми зв’яжемося з вами, коли додаток буде випущено. До побачення з іншого боку.",
    "password": "Пароль",
    "email": "Електронна адреса",
    "fullname": "Повне ім'я",
    "fulladdress": "Повна адреса",
    "gender": "Стать",
    "createyouraccount": "створити свій обліковий запис",
    "changelanguage": "Змініть свою мову",
    "userterms": "Загальні положення та умови користувача",
    "privacypolicy": "Політика конфіденційності",
    "copyrightmessage": "© Copyright 2020 Armbet. Всі права захищені.",
    "backtohomepage": "Повернутися на головну сторінку",
    "message": "повідомлення",
    "close": "Закрити",
    "accountalreadyexists": "Обліковий запис із цією електронною адресою вже існує. Можливо, ви вже приєдналися до спільноти.",
    "notFound.title": "404",
    "notFound.text": "На жаль, цю сторінку не вдалося знайти ...",
    "errorhasoccured": "На жаль, сталася помилка ... Вибачте за незручності.",
    "networkerror": "На жаль, не вдалося підключитися до сервера ...",
    "emailcouldntsend": "Просимо вибачення, але ми могли надіслати електронний лист. Перевірте свою електронну адресу та повторіть спробу.",
    "contactus": "Зв'яжіться з нами",
    "nothanks": "Ні, дякую",
    "improvetranslationstitle": "Допоможіть нам покращити переклади!",
    "improvetranslationstext": "Зверніться до нас, якщо ви хочете допомогти покращити переклад",

    "accountactivated": "рахунок активовано!",
    "accountalreadyactivated": "Ваш рахунок уже активовано!",
    "accountactivatedtext": "Ви створили свій обліковий запис для програми Armbet, яка незабаром. Запам’ятайте свою електронну пошту та пароль, і ми зв’яжемось з вами, коли додаток буде випущено. Побачимося з іншого боку.",
    "linknotvalid": "Посилання минуло",
    "linknotvalidtext": "Посилання, яке ви ввели, недійсне, якщо ви хочете активувати свій акаунт, будь ласка, вимагайте, щоб у вашу вхідну скриньку надсилався новий електронний лист.",

    "privacypolicy.1.text1": "На сайті armbet.com, доступному з www.armbet.com, одним з наших головних пріоритетів є конфіденційність наших відвідувачів. Цей документ щодо політики конфіденційності містить типи інформації, яку збирає та записує armbet.com, і як ми її використовуємо.",
    "privacypolicy.1.text2": "Якщо у вас є додаткові запитання або вам потрібна додаткова інформація про нашу Політику конфіденційності, не соромтеся зв’язатися з нами.",
    "privacypolicy.1.text3": "Ця Політика конфіденційності поширюється лише на нашу діяльність в Інтернеті та є дійсною для відвідувачів нашого веб-сайту щодо інформації, якою вони поділилися та / або збирають на armbet.com. Ця політика не застосовується до будь-якої інформації, зібраної офлайн або через інші канали, крім цього веб-сайту.",
    "privacypolicy.2.title": "Згода",
    "privacypolicy.2.text1": "Використовуючи наш веб-сайт, Ви даєте згоду на нашу Політику конфіденційності та погоджуєтесь з її умовами.",
    "privacypolicy.3.title": "Інформація, яку ми збираємо",
    "privacypolicy.3.text1": "Особиста інформація, яку ви просите надати, та причини, чому вас просять надати, будуть зрозумілі вам у той момент, коли ми просимо вас надати вашу особисту інформацію.",
    "privacypolicy.3.text2": "Якщо ви зв’яжетеся з нами безпосередньо, ми можемо отримати додаткову інформацію про вас, наприклад ваше ім’я, електронну адресу, номер телефону, вміст повідомлення та / або вкладення, яке ви можете нам надіслати, та будь-яку іншу інформацію, яку ви можете вибрати.",
    "privacypolicy.3.text3": "Коли ви реєструєтесь для облікового запису, ми можемо попросити вашу контактну інформацію, включаючи такі елементи, як ім’я, назва компанії, адреса, електронна адреса та номер телефону.",
    "privacypolicy.4.title": "Як ми використовуємо вашу інформацію",
    "privacypolicy.4.text1": "Ми використовуємо інформацію, яку ми збираємо, різними способами, включаючи:",
    "privacypolicy.4.text2": "Забезпечуйте, працюйте та підтримуйте нашу веб-службу",
    "privacypolicy.4.text3": "Вдосконалити, персоналізувати та розширити нашу веб-сторінку",
    "privacypolicy.4.text4": "Зрозумійте та проаналізуйте, як ви використовуєте наш веб-сайт",
    "privacypolicy.4.text5": "Розробка нових продуктів, послуг, функцій та функціональності",
    "privacypolicy.4.text6": "Спілкуйтеся з вами безпосередньо або через одного з наших партнерів, в тому числі для обслуговування клієнтів, щоб надати вам оновлення та іншу інформацію, що стосується веб-сторінки, а також для маркетингових та рекламних цілей",
    "privacypolicy.4.text7": "Надіслати вам електронні листи",
    "privacypolicy.4.text8": "Знайти та запобігти шахрайству",
    "privacypolicy.5.title": "Файли журналу",
    "privacypolicy.5.text1": "armbet.com дотримується стандартної процедури використання файлів журналів. Ці файли реєструють відвідувачів, коли вони відвідують веб-сайти. Всі хостингові компанії роблять це і є частиною аналітики хостингових послуг. Інформація, зібрана за допомогою файлів журналів, включає адреси Інтернет-протоколу (IP), тип браузера, постачальника послуг Інтернету (ISP), штамп дати та часу, сторінки переходу / виходу та, можливо, кількість кліків. Вони не пов'язані з будь-якою інформацією, яку можна ідентифікувати особисто. Мета інформації - аналіз тенденцій, адміністрування сайту, відстеження руху користувачів на веб-сайті та збір демографічної інформації.",
    "privacypolicy.6.title": "Політика конфіденційності рекламних партнерів",
    "privacypolicy.6.text1": "Ви можете ознайомитись із цим списком, щоб знайти політику конфіденційності для кожного з рекламних партнерів armbet.com.",
    "privacypolicy.6.text2": "Сторонні рекламні сервери або рекламні мережі використовують такі технології, як файли cookie, JavaScript або веб-маяки, які використовуються у відповідній рекламі та посиланнях на armbet.com, які надсилаються безпосередньо до браузера користувачів. Вони автоматично отримують вашу IP-адресу, коли це відбувається. Ці технології використовуються для вимірювання ефективності їх рекламних кампаній та / або для персоналізації рекламного контенту, який ви бачите на веб-сайтах, які ви відвідуєте.",
    "privacypolicy.6.text3": "Зауважте, що armbet.com не має доступу до цих файлів cookie та контролю над ними, які використовуються сторонніми рекламодавцями.",
    "privacypolicy.7.title": "Політика конфіденційності сторонніх організацій",
    "privacypolicy.7.text1": "Політика конфіденційності armbet.com не поширюється на інших рекламодавців або веб-сайти. Таким чином, ми радимо вам ознайомитися з відповідною Політикою конфіденційності цих сторонніх рекламних серверів для отримання більш детальної інформації. Це може включати їх практику та інструкції щодо відмови від певних варіантів. Повний перелік цих Політики конфіденційності та їх посилання ви можете знайти тут: Посилання щодо політики конфіденційності.",
    "privacypolicy.7.text2": "Ви можете вимкнути файли cookie через ваші окремі параметри браузера. Щоб дізнатися більш детальну інформацію про керування файлами cookie з певними веб-браузерами, їх можна знайти на відповідних веб-сайтах браузерів. Що таке печиво?",
    "privacypolicy.8.title": "Права конфіденційності CCPA (не продавати мою особисту інформацію)",
    "privacypolicy.8.text1": "Відповідно до CCPA, серед інших прав, каліфорнійські споживачі мають право:",
    "privacypolicy.8.text2": "Попросити, щоб бізнес, який збирає особисті дані споживача, розкривав категорії та конкретні особисті дані, які бізнес зібрав про споживачів.",
    "privacypolicy.8.text3": "Попросити компанію видалити будь-які особисті дані про споживача, які збирав бізнес.",
    "privacypolicy.8.text4": "Попросіть, щоб бізнес, який продає персональні дані споживача, не продавав особисті дані споживача.",
    "privacypolicy.8.text5": "Якщо ви зробите запит, у нас є один місяць для відповіді на вас. Якщо ви хочете скористатися будь-яким із цих прав, зв’яжіться з нами.",
    "privacypolicy.9.title": "Права на захист даних GDPR",
    "privacypolicy.9.text1": "Ми хотіли б переконатися, що ви повністю знаєте всі свої права на захист даних. Кожен користувач має право на таке:",
    "privacypolicy.9.text2": "Право на доступ - Ви маєте право вимагати копії ваших персональних даних. Ми можемо стягнути з вас невелику плату за цю послугу.",
    "privacypolicy.9.text3": "Право на виправлення - Ви маєте право вимагати, щоб ми виправляли будь-яку інформацію, яку ви вважаєте недостовірною. Ви також маєте право вимагати, щоб ми заповнили інформацію, яку ви вважаєте неповною.",
    "privacypolicy.9.text4": "Право на стирання - Ви маєте право вимагати, щоб ми видалили ваші персональні дані, за певних умов.",
    "privacypolicy.9.text5": "Право обмежувати обробку - Ви маєте право вимагати, щоб ми обмежили обробку ваших персональних даних за певних умов.",
    "privacypolicy.9.text6": "Право на заперечення проти обробки - Ви маєте право заперечити проти нашої обробки ваших персональних даних за певних умов.",
    "privacypolicy.9.text7": "Право на переносимість даних - Ви маєте право вимагати перенесення даних, які ми зібрали, до іншої організації або безпосередньо до вас за певних умов.",
    "privacypolicy.9.text8": "Якщо ви зробите запит, у нас є один місяць для відповіді на вас. Якщо ви хочете скористатися будь-яким із цих прав, зв’яжіться з нами.",
    "privacypolicy.10.title": "Інформація про дітей",
    "privacypolicy.10.text1": "Інша частина нашого пріоритету - це захист дітей під час використання Інтернету. Ми закликаємо батьків та опікунів спостерігати, брати участь у та / або контролювати та керувати їхньою онлайн-діяльністю.",
    "privacypolicy.10.text2": "armbet.com свідомо не збирає будь-яку особисту інформацію від дітей до 13 років. Якщо ви вважаєте, що ваша дитина надала таку інформацію на нашому веб-сайті, ми наполегливо рекомендуємо негайно зв’язатися з нами, і ми зробимо все можливе, щоб негайно видалити таку інформацію з наших записів.",
    "user.terms.1.text1": "Ці умови визначають правила та правила користування веб-сайтом Armbet, Inc.",
    "user.terms.1.text2": "Armbet, Inc знаходиться за адресою:",
    "user.terms.1.text3": "Leslie St 5863, блок 610, Північний Йорк <br /> M2H 1J8 - Онтаріо, Канада",
    "user.terms.1.text4": "Заходячи на цей веб-сайт, ми передбачаємо, що ви повністю приймаєте ці умови. Не продовжуйте використовувати веб-сайт Armbet, Inc, якщо ви не приймаєте всі умови та умови, зазначені на цій сторінці.",
    "user.terms.1.text5": "Наступна термінологія застосовується до цих Умов, Положення про конфіденційність та Повідомлення про відмову та будь-яких або всіх Угод: 'Клієнт', 'Ви' та 'Ваш' посилається на вас, особу, яка отримує доступ до цього веб-сайту та приймає Загальні положення та умови Компанії. 'Компанія', 'Ми самі', 'Ми', 'Наші' та 'Нас' - це наша компанія. 'Сторона', 'Сторони' або 'Нас' позначають як Клієнта, так і нас самих, або Клієнта, або нас самих. Усі умови стосуються пропозиції, прийняття та врахування оплати, необхідної для здійснення процесу нашої допомоги Клієнтові найбільш відповідним чином, чи то офіційними зустрічами з визначеною тривалістю, чи будь-якими іншими способами, для прямої мети зустрічі з Потреби Клієнта у наданні заявлених Компанією послуг / продуктів, відповідно до чинного законодавства Канади. Будь-яке використання вищевказаної термінології чи інших слів у однині, множині, написанні великої літери та / або він / вона, вони вважаються взаємозамінними і тому відносяться до того ж.",
    "user.terms.2.title": "Гіперпосилання на наш Зміст",
    "user.terms.2.text1": "Наступні організації можуть посилатися на наш веб-сайт без попереднього письмового схвалення:",
    "user.terms.2.text2": "Урядові установи;",
    "user.terms.2.text3": "Пошукові системи;",
    "user.terms.2.text4": "Інформаційні організації;",
    "user.terms.2.text5": "Інтернет-дистриб'ютори каталогів, коли вони перераховують нас у довіднику, можуть посилатися на наш веб-сайт так само, як і гіперпосилання на веб-сайти інших перелічених підприємств;",
    "user.terms.2.text6": "Акредитовані по всьому бізнесу, за винятком залучення неприбуткових організацій, торгових центрів благодійних організацій та груп благодійного збору коштів, які можуть не мати гіперпосилання на наш веб-сайт.",
    "user.terms.2.text7": "Ці організації можуть посилатися на нашу домашню сторінку, публікації або іншу інформацію про веб-сайт, якщо посилання: (a) жодним чином не вводить в оману; (b) не передбачає помилкового спонсорства, схвалення або схвалення зв'язуючої сторони та її товарів чи послуг; та (c) вписується в контекст сайту, що пов'язує сторону.",
    "user.terms.2.text8": "Ми можемо розглянути та затвердити на власний розсуд інші запити посилання від таких типів організацій:",
    "user.terms.2.text9": "широко відомі споживчі та / або ділові джерела інформації, такі як Торгові палати, Американська автомобільна асоціація, AARP та Споживчий союз;",
    "user.terms.2.text10": "сайти спільноти dot.com;",
    "user.terms.2.text11": "асоціації або інші групи, що представляють благодійні організації, включаючи сайти, що надають благодійні організації,",
    "user.terms.2.text12": "онлайн-дистриб'ютори каталогів;",
    "user.terms.2.text13": "Інтернет-портали;",
    "user.terms.2.text14": "бухгалтерські, юридичні та консалтингові фірми, основними клієнтами яких є бізнес; і",
    "user.terms.2.text15": "навчальні заклади та торгові асоціації.",
    "user.terms.2.text16": "Ми будемо схвалювати запити на посилання від цих організацій, якщо визначимо, що: (a) посилання не відображатиметься негативно на нас або на нашому акредитованому бізнесі (наприклад, торгові асоціації чи інші організації, що представляють за собою підозрілі види бізнесу, такі як робота на роботі домашні можливості, забороняється зв'язуватися); (b) організація не має незадовільних даних щодо нас; (c) користь для нас від видимості, пов'язаної з гіперпосиланням, переважає відсутність Armbet, Inc; та (d) де посилання знаходиться в контексті загальної інформації про ресурси або іншим чином відповідає редакційному вмісту в бюлетені або подібному продукті, що сприяє місії організації.",
    "user.terms.2.text17": "Ці організації можуть посилатися на нашу домашню сторінку, публікації або іншу інформацію про веб-сайт, якщо посилання: (a) жодним чином не вводить в оману; (b) не передбачає помилкового спонсорства, схвалення або схвалення пов'язаної сторони та її товарів чи послуг; та (c) вписується в контекст сайту, що пов'язує сторону.",
    "user.terms.2.text18": "Якщо ви належите до організацій, перелічених у пункті 2 вище, і вам цікаво посилання на наш веб-сайт, ви повинні повідомити нас, надіславши електронний лист на адресу",
    "user.terms.2.text19": "Будь ласка, вкажіть своє ім’я, ім’я організації, контактну інформацію (наприклад, номер телефону та / або адресу електронної пошти), а також URL-адресу вашого веб-сайту, список усіх URL-адрес, з яких ви маєте намір посилання на наш веб-сайт, і список URL-адрес (ів) на нашому веб-сайті, до яких ви хочете зв’язатись. Дозвольте 2-3 тижні для відповіді.",
    "user.terms.2.text20": "Затверджені організації можуть гіперпосилання на наш веб-сайт наступним чином:",
    "user.terms.2.text21": "Використовуючи наше корпоративне найменування; або",
    "user.terms.2.text22": "Використовуючи єдиний локатор ресурсів (веб-адреса), пов'язаний з цим; або",
    "user.terms.2.text23": "Використовуючи будь-який інший опис нашого веб-сайту чи матеріалів, пов’язаних із цим, має сенс у контексті та форматі вмісту на сайті сторони, що посилається.",
    "user.terms.2.text24": "Забороняється використовувати логотип Armbet, Inc або інші твори мистецтва для посилання відсутніх ліцензійних угод щодо торговельної марки.",
    "user.terms.3.title": "Видалення посилань з нашого веб-сайту",
    "user.terms.3.text1": "Якщо ви знайдете будь-яке посилання на нашому веб-сайті або будь-який зв'язаний веб-сайт, який є незгодним з будь-якої причини, ви можете зв’язатися з нами з цього питання. Ми розглянемо запити на видалення посилань, але не матимемо цього зобов’язання чи відповідати вам безпосередньо.",
    "user.terms.3.text2": "Хоча ми намагаємось забезпечити правильність інформації на цьому веб-сайті, ми не гарантуємо його повноти чи точності; ми також не беремо на себе зобов'язання гарантувати, що веб-сайт залишається доступним або що матеріали на веб-сайті оновлюються.",
    "user.terms.4.title": "Зміст відповідальності",
    "user.terms.4.text1": "Ми не несемо відповідальності за будь-який вміст, що з’являється на вашому веб-сайті. Ви погоджуєтесь відшкодувати та захистити нас від усіх позовів, що виникають із Вашого веб-сайту або засновані на ньому. Жодна посилання не може відображатися на будь-якій сторінці вашого веб-сайту або в будь-якому контексті, що містить вміст або матеріали, які можуть трактуватися як неправдиві, нецензурні чи злочинні, або які порушують, інакше порушують, або виступають за порушення чи інше порушення будь-якого права третіх осіб",
    "user.terms.5.title": "Відмова від відповідальності",
    "user.terms.5.text1": "У максимальній мірі, дозволеній чинним законодавством, ми виключаємо всі представлення, гарантії та умови, що стосуються нашого веб-сайту та використання цього веб-сайту (включаючи, без обмежень, будь-які гарантії, передбачені законодавством щодо задовільної якості, придатності до цілей та / або використання розумного догляду та майстерності). Ніщо в цій відмові не буде:",
    "user.terms.5.text2": "обмежувати чи виключати нашу або вашу відповідальність за смерть чи тілесні ушкодження внаслідок необережності;",
    "user.terms.5.text3": "обмежувати чи виключати нашу або вашу відповідальність за шахрайство або шахрайське неправдиве представлення;",
    "user.terms.5.text4": "обмежувати будь-які наші або ваші зобов’язання будь-яким способом, який заборонений законодавством; або",
    "user.terms.5.text5": "виключити будь-які наші або ваші зобов’язання, які не можуть бути виключені відповідно до чинного законодавства.",
    "user.terms.5.text6": "Обмеження та виключення відповідальності, встановлені в цьому розділі та в інших місцях цього відмови: (a) застосовуються до попереднього пункту; та (b) керувати усіма зобов'язаннями, що виникають за умови відмови від відповідальності або стосовно предмета цієї відмови від відповідальності, включаючи зобов'язання, що виникають у договорі, внаслідок делікту (включаючи недбалість) та за порушення встановленого законодавством обов'язку.",
    "user.terms.5.text7": "У міру, коли веб-сайт та інформація та послуги на веб-сайті надаються безкоштовно, ми не несемо відповідальності за будь-які втрати чи збитки будь-якого характеру."
}