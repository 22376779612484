export default {
    "_colon_": ":",
    "languages.english": "Ingilizce",
    "languages.french": "Fransızca",
    "languages.spanish": "İspanyol",
    "languages.german": "Almanca",
    "languages.hindi": "Hintçe",
    "languages.italian": "İtalyan",
    "languages.georgian": "Gürcü",
    "languages.portuguese": "Portekizce",
    "languages.russian": "Rusça",
    "languages.swedish": "İsveççe",
    "languages.turkish": "Türk",
    "languages.ukrainian": "Ukrayna",
    "logoAlt": "Armbet logosu",
    "navBar.item1.text": "O nasıl çalışır?",
    "navBar.item2.text": "Gelecek özellikler",
    "navBar.item3.text": "Hesap oluştur",
    "headline": "Bilek Güreşi Dünyasına Hoşgeldiniz",
    "headline.part1": "Dünyasına hoş geldiniz",
    "headline.part2": "bilek Güreşi",
    "headlineText": "Armbet, çok daha fazla özellik ile birlikte takım kurup kavramak için ortaklar bulmanızı sağlayan bir bilek güreşi uygulamasıdır.",
    "about.title": "Üye olmak",
    "about.text": "Bir profil oluşturduktan sonra bilek güreşi topluluğuna katılacaksınız. Haritamızı kullanarak başkalarını bulabilir, mesajlaşabilir, buluşabilir, birlikte antrenman yapabilir ve rekabet edebilirsiniz.",
    "features1.title": "bulmak",
    "features1.text": "Bölgenizdeki veya ilgi duyduğunuz bölgedeki bilek güreşçileri bulun",
    "features2.title": "Sohbet",
    "features2.text": "Etkinlik ayarlamak için kullanıcılara mesaj gönderin",
    "features3.title": "Eğitin ve rekabet edin",
    "features3.text": "Birlikte antrenman yapmak ve rekabet etmek için buluşun",
    "comingSoon.title": "Etkinlik bul ve oluştur, saflara katıl!",
    "comingSoon.text": "Yakında etrafınızdaki etkinlikleri bulabilecek ve hatta kendi etkinliklerinizi oluşturabileceksiniz. Tüm bilek güreşçileri için eksiksiz bir sıralama sistemi de planlanacaktır! Ve burada durmayacağız ...",
    "comingSoon.part1": "Bul ve oluştur",
    "comingSoon.part2": "Etkinlikler",
    "comingSoon.part3":", saflara",
    "comingSoon.part4":" katıl",
    "comingsoon": "Çok yakında",
    "signUp.title": "Şimdi Katıl",
    "signUp.text": "Bilek güreşi dünyasına girin. En eski ve erişilebilir spor. Şimdi kaydolun ve uygulamanın ne zaman yayınlandığını size bildireceğiz.",
    "signUp.part1": "Katılmak",
    "signUp.part2": "şimdi",
    "signup": "Hesap oluştur",
    "genders.male": "Erkek",
    "genders.female": "Kadın",
    "genders.other": "Diğer",
    "signUpSuccess.title": "kayıt olduğunuz için teşekkürler!",
    "signUpSuccess.text": "Yakında yapılacak olan Armbet uygulaması için hesabınızı oluşturdunuz. Hesabınızı doğrulamak ve etkinleştirmek için lütfen gelen kutunuzu kontrol edin. Giriş bilgilerinizi hatırlayın ve uygulama yayınlandığında sizinle iletişime geçeceğiz. diğer tarafta.",
    "password": "Parola",
    "email": "E",
    "fullname": "Ad Soyad",
    "fulladdress": "Açık adres",
    "gender": "Cinsiyet",
    "createyouraccount": "hesabını oluştur",
    "changelanguage": "Dilinizi değiştirin",
    "userterms": "Kullanıcı hüküm ve koşulları",
    "privacypolicy": "Gizlilik Politikası",
    "copyrightmessage": "© Telif Hakkı 2020 Armbet. Tüm hakları Saklıdır.",
    "backtohomepage": "ana sayfaya geri dön",
    "message": "İleti",
    "close": "Kapat",
    "accountalreadyexists": "Bu e-posta adresine sahip bir hesap zaten var. Topluluğa zaten katılmış olabilirsiniz.",
    "notFound.title": "404",
    "notFound.text": "Üzgünüz ama bu sayfa bulunamadı ...",
    "errorhasoccured": "Maalesef bir hata oluştu ... Rahatsızlık için özür dileriz.",
    "networkerror": "Maalesef sunucuya bağlanamadık ...",
    "emailcouldntsend": "Maalesef e-postayı gönderebiliriz. Lütfen e-posta adresinizi kontrol edip tekrar deneyin.",
    "contactus": "Bize Ulaşın",
    "nothanks": "Hayır teşekkürler",
    "improvetranslationstitle": "Çevirileri geliştirmemize yardımcı olun!",
    "improvetranslationstext": "Çevirilerin geliştirilmesine yardımcı olmak istiyorsanız lütfen bizimle iletişime geçin.",

    "accountactivated": "hesabı etkinleştirildi!",
    "accountalreadyactivated": "Hesabınız daha önceden aktive edilmiştir!",
    "accountactivatedtext": "Yakında çıkacak olan Armbet uygulaması için hesabınızı oluşturdunuz. E-postanızı ve şifrenizi hatırlayın, uygulama yayınlandığında sizinle iletişim kuracağız. Diğer tarafta görüşürüz.",
    "linknotvalid": "Bağlantının süresi doldu",
    "linknotvalidtext": "Girdiğiniz bağlantı geçerli değil, hesabınızı etkinleştirmek istiyorsanız lütfen gelen kutunuza yeni bir e-posta gönderilmesini isteyin.",

    "privacypolicy.1.text1": "Www.armbet.com adresinden erişilebilen armbet.com adresinde temel önceliklerimizden biri ziyaretçilerimizin gizliliğidir. Bu Gizlilik Politikası belgesi, armbet.com tarafından toplanan ve kaydedilen bilgi türlerini ve bunları nasıl kullandığımızı içerir.",
    "privacypolicy.1.text2": "Başka sorularınız varsa veya Gizlilik Politikamız hakkında daha fazla bilgi istiyorsanız, bizimle iletişime geçmekten çekinmeyin.",
    "privacypolicy.1.text3": "Bu Gizlilik Politikası sadece çevrimiçi etkinliklerimiz için geçerlidir ve armbet.com'da paylaştıkları ve / veya topladıkları bilgilerle ilgili olarak web sitemizi ziyaret edenler için geçerlidir. Bu politika, çevrimdışı olarak veya bu web sitesi dışındaki kanallar aracılığıyla toplanan hiçbir bilgi için geçerli değildir.",
    "privacypolicy.2.title": "Razı olmak",
    "privacypolicy.2.text1": "Web sitemizi kullanarak, Gizlilik Politikamızı kabul etmiş ve şartlarını kabul etmiş olursunuz.",
    "privacypolicy.3.title": "Topladığımız bilgiler",
    "privacypolicy.3.text1": "Sağlamanız istenen kişisel bilgiler ve bu bilgileri sağlamanızın istenmesinin nedenleri, kişisel bilgilerinizi vermenizi istediğimiz noktada size açıklanacaktır.",
    "privacypolicy.3.text2": "Bizimle doğrudan iletişime geçerseniz, adınız, e-posta adresiniz, telefon numaranız, iletinin içeriği ve / veya bize gönderebileceğiniz ekler ve sağlamayı seçebileceğiniz diğer bilgiler gibi ek bilgiler alabiliriz.",
    "privacypolicy.3.text3": "Bir Hesap açtığınızda, ad, şirket adı, adres, e-posta adresi ve telefon numarası gibi öğeler dahil olmak üzere iletişim bilgilerinizi isteyebiliriz.",
    "privacypolicy.4.title": "Bilgilerinizi nasıl kullanıyoruz",
    "privacypolicy.4.text1": "Topladığımız bilgileri aşağıdakiler de dahil olmak üzere çeşitli şekillerde kullanıyoruz:",
    "privacypolicy.4.text2": "Web sitemizi sağlayın, işletin ve bakımını yapın",
    "privacypolicy.4.text3": "Web sitemizi geliştirin, kişiselleştirin ve genişletin",
    "privacypolicy.4.text4": "Web sitemizi nasıl kullandığınızı anlayın ve analiz edin",
    "privacypolicy.4.text5": "Yeni ürünler, hizmetler, özellikler ve işlevler geliştirin",
    "privacypolicy.4.text6": "Webste, pazarlama ve promosyon amaçlı güncellemeler ve diğer bilgileri sağlamak için sizinle doğrudan veya müşteri hizmetleri de dahil ortaklarımızdan biri aracılığıyla iletişim kurun",
    "privacypolicy.4.text7": "Size e-posta gönder",
    "privacypolicy.4.text8": "Sahteciliği bulma ve önleme",
    "privacypolicy.5.title": "Log dosyaları",
    "privacypolicy.5.text1": "armbet.com günlük dosyalarını kullanma konusunda standart bir prosedür izler. Bu dosyalar ziyaretçileri web sitelerini ziyaret ettiklerinde günlüğe kaydeder. Tüm barındırma şirketleri bunu ve barındırma hizmetlerinin analizlerinin bir parçasını yapar. Günlük dosyaları tarafından toplanan bilgiler arasında internet protokolü (IP) adresleri, tarayıcı türü, İnternet Servis Sağlayıcısı (İSS), tarih ve saat damgası, yönlendirme / çıkış sayfaları ve muhtemelen tıklama sayısı bulunur. Bunlar kişisel olarak tanımlanabilir herhangi bir bilgi ile bağlantılı değildir. Bilgilerin amacı eğilimleri analiz etmek, siteyi yönetmek, kullanıcıların web sitesindeki hareketlerini izlemek ve demografik bilgi toplamaktır.",
    "privacypolicy.6.title": "Reklam Ortakları Gizlilik Politikaları",
    "privacypolicy.6.text1": "Armbet.com'un reklamcılık ortaklarının her biri için Gizlilik Politikası'nı bulmak için bu listeye başvurabilirsiniz.",
    "privacypolicy.6.text2": "Üçüncü taraf reklam sunucuları veya reklam ağları, armbet.com'da görünen ve doğrudan kullanıcıların tarayıcısına gönderilen ilgili reklamlarında ve bağlantılarında kullanılan çerezler, JavaScript veya Web İşaretçileri gibi teknolojileri kullanır. Bu gerçekleştiğinde IP adresinizi otomatik olarak alırlar. Bu teknolojiler, reklam kampanyalarının etkinliğini ölçmek ve / veya ziyaret ettiğiniz web sitelerinde gördüğünüz reklam içeriğini kişiselleştirmek için kullanılır.",
    "privacypolicy.6.text3": "Armbet.com'un üçüncü taraf reklamverenler tarafından kullanılan bu çerezlere erişimi veya kontrolü üzerinde olmadığını unutmayın.",
    "privacypolicy.7.title": "Üçüncü Şahıs Gizlilik Politikaları",
    "privacypolicy.7.text1": "armbet.com'un Gizlilik Politikası diğer reklamverenler veya web siteleri için geçerli değildir. Bu nedenle, daha ayrıntılı bilgi için bu üçüncü taraf reklam sunucularının ilgili Gizlilik Politikalarına başvurmanızı öneririz. Bazı seçeneklerin nasıl devre dışı bırakılacağıyla ilgili uygulamalarını ve talimatlarını içerebilir. Bu Gizlilik Politikalarının ve bağlantılarının tam bir listesini burada bulabilirsiniz: Gizlilik Politikası Bağlantıları.",
    "privacypolicy.7.text2": "Tek tek tarayıcı seçeneklerinizden çerezleri devre dışı bırakmayı seçebilirsiniz. Belirli web tarayıcılarıyla çerez yönetimi hakkında daha ayrıntılı bilgi edinmek için, tarayıcıların ilgili web sitelerinde bulunabilir. Çerez Nedir?",
    "privacypolicy.8.title": "CCPA Gizlilik Hakları (Kişisel Bilgilerimi Satma)",
    "privacypolicy.8.text1": "CCPA kapsamında, diğer hakların yanı sıra, Kaliforniya tüketicileri:",
    "privacypolicy.8.text2": "Bir tüketicinin kişisel verilerini toplayan bir işletmenin, bir işletmenin tüketiciler hakkında topladığı kategorileri ve belirli kişisel veri parçalarını açıklamasını isteyin.",
    "privacypolicy.8.text3": "Bir işletmenin, bir işletmenin topladığı tüketici hakkındaki tüm kişisel verileri silmesini isteyin.",
    "privacypolicy.8.text4": "Tüketicinin kişisel verilerini satan bir işletmenin tüketicinin kişisel verilerini satmasını istemeyin.",
    "privacypolicy.8.text5": "Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak isterseniz, lütfen bizimle iletişime geçin.",
    "privacypolicy.9.title": "GDPR Veri Koruma Hakları",
    "privacypolicy.9.text1": "Tüm veri koruma haklarınızın tamamen farkında olduğunuzdan emin olmak istiyoruz. Her kullanıcı aşağıdakilere yetkilidir:",
    "privacypolicy.9.text2": "Erişim hakkı - Kişisel verilerinizin kopyalarını isteme hakkına sahipsiniz. Bu hizmet için sizden küçük bir ücret talep edebiliriz.",
    "privacypolicy.9.text3": "Düzeltme hakkı - Yanlış olduğuna inandığınız bilgileri düzeltmemizi talep etme hakkına sahipsiniz. Ayrıca, eksik olduğuna inandığınız bilgileri doldurmamızı talep etme hakkına da sahipsiniz.",
    "privacypolicy.9.text4": "Silme hakkı - Belirli koşullar altında kişisel verilerinizi silmemizi talep etme hakkına sahipsiniz.",
    "privacypolicy.9.text5": "İşlemeyi kısıtlama hakkı - Belirli koşullar altında kişisel verilerinizin işlenmesini kısıtlamamızı talep etme hakkına sahipsiniz.",
    "privacypolicy.9.text6": "İşlemeye itiraz etme hakkı - Belirli koşullar altında kişisel verilerinizin işlenmesine itiraz etme hakkına sahipsiniz.",
    "privacypolicy.9.text7": "Veri taşınabilirliği hakkı - Topladığımız verileri belirli koşullar altında başka bir kuruluşa veya doğrudan size aktarmamızı isteme hakkına sahipsiniz.",
    "privacypolicy.9.text8": "Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak isterseniz, lütfen bizimle iletişime geçin.",
    "privacypolicy.10.title": "Çocuk Bilgileri",
    "privacypolicy.10.text1": "Önceliğimizin bir başka kısmı da interneti kullanırken çocukların korunmasıdır. Ebeveynleri ve velileri çevrimiçi etkinliklerini gözlemlemeye, katılmaya ve / veya izlemeye ve yönlendirmeye teşvik ediyoruz.",
    "privacypolicy.10.text2": "armbet.com 13 yaşın altındaki çocuklardan bilerek Kişisel Tanımlanabilir Bilgi toplamamaktadır Çocuğunuzun bu tür bilgileri web sitemizde sağladığını düşünüyorsanız, hemen bizimle iletişime geçmenizi önemle tavsiye ederiz. bu tür bilgileri derhal kayıtlarımızdan çıkarın.",
    "user.terms.1.text1": "Bu şartlar ve koşullar Armbet, Inc'in Web Sitesinin kullanımına ilişkin kural ve düzenlemeleri ana hatlarıyla belirtir.",
    "user.terms.1.text2": "Armbet, Inc şuradadır:",
    "user.terms.1.text3": "Leslie St 5863 Ünite 610 Kuzey York <br /> M2H 1J8 - Ontario, Kanada",
    "user.terms.1.text4": "Bu web sitesine erişerek bu hüküm ve koşulları tam olarak kabul ettiğinizi varsayıyoruz. Bu sayfada belirtilen tüm hüküm ve koşulları kabul etmiyorsanız Armbet, Inc'in web sitesini kullanmaya devam etmeyin.",
    "user.terms.1.text5": "Aşağıdaki terimler bu Şartlar ve Koşullar, Gizlilik Bildirimi ve Feragat Bildirimi ve herhangi bir veya tüm Anlaşmalar için geçerlidir: 'Müşteri', 'Siz' ve 'Sizin' bu web sitesine erişen ve Şirketin hüküm ve koşullarını kabul eden kişiyi ifade eder. 'Şirket', 'Kendimiz', 'Biz', 'Bizim' ve 'Biz' Şirketimizi ifade eder. 'Parti', 'Taraflar' veya 'Biz', hem Müşteri'yi hem de kendimizi veya Müşteri'yi ya da kendimizi ifade eder. Tüm şartlar, belirli bir sürenin resmi toplantılarında veya açık bir şekilde toplantı amacıyla, Müşteriye yardım sürecimizi en uygun şekilde yerine getirmek için gerekli olan ödeme teklifini, kabulünü ve değerlendirilmesini ifade eder. Müşterinin, Kanada'nın yürürlükteki yasalarına uygun olarak ve Şirket'in beyan ettiği hizmetlerin / ürünlerin sağlanması ile ilgili ihtiyaçları. Yukarıdaki terminolojinin veya diğer kelimelerin tekil, çoğul, büyük / küçük harf kullanımı ve / veya kendilerindeki herhangi bir kullanımı, birbirlerinin yerine geçebilir ve dolayısıyla bunlara atıfta bulunularak alınır.",
    "user.terms.2.title": "İçeriğimize Köprü Oluşturma",
    "user.terms.2.text1": "Aşağıdaki kuruluşlar önceden yazılı onay alınmadan Web sitemize bağlanabilir:",
    "user.terms.2.text2": "Devlet kurumları;",
    "user.terms.2.text3": "Arama motorları;",
    "user.terms.2.text4": "Haber kuruluşları;",
    "user.terms.2.text5": "Bizi dizinde listelediklerinde çevrimiçi dizin dağıtıcıları, Web sitemize, listelenen diğer işletmelerin Web sitelerine köprü oluşturdukları şekilde bağlanabilir;",
    "user.terms.2.text6": "Web sitemize hiperlink oluşturamayabilecek kar amacı gütmeyen kuruluşlar, yardım alışveriş merkezleri ve yardım fonu toplama grupları istemek dışında Sistem Çapında Akredite İşletmeler.",
    "user.terms.2.text7": "Bu kuruluşlar, ana sayfamıza, yayınlara veya diğer Web sitesi bilgilerine bağlantı (A) hiçbir şekilde yanıltıcı olmadığı sürece bağlanabilir; (b) bağlantı kurulacak tarafın ve ürün veya hizmetlerinin yanlış bir şekilde sponsorluğunu, onayını veya onayını ima etmez; ve (c) bağlanan tarafın sitesi bağlamına uyar.",
    "user.terms.2.text8": "Aşağıdaki kuruluş türlerinden gelen diğer bağlantı taleplerini tamamen kendi takdirimize göre değerlendirebilir ve onaylayabiliriz:",
    "user.terms.2.text9": "Ticaret Odaları, Amerikan Otomobil Birliği, AARP ve Tüketiciler Birliği gibi yaygın olarak bilinen tüketici ve / veya ticari bilgi kaynakları;",
    "user.terms.2.text10": "dot.com topluluk siteleri;",
    "user.terms.2.text11": "hayır kurumu veren siteler dahil, hayır kurumlarını temsil eden dernekler veya diğer gruplar,",
    "user.terms.2.text12": "çevrimiçi dizin dağıtıcıları;",
    "user.terms.2.text13": "internet portalları;",
    "user.terms.2.text14": "birincil müşterileri işletme olan muhasebe, hukuk ve danışmanlık firmaları; ve",
    "user.terms.2.text15": "eğitim kurumları ve ticaret birlikleri.",
    "user.terms.2.text16": "Aşağıdakileri tespit edersek, bu kuruluşlardan gelen bağlantı taleplerini onaylayacağız: (a) bağlantının bize veya akredite edilmiş işlerimize (örneğin ticaret dernekleri veya işyerinde iş gibi şüpheli iş türlerini temsil eden diğer kuruluşlar) olumsuz yansımayacağını ev fırsatları, bağlantıya izin verilmez); (b) örgütün bizimle tatmin edici olmayan bir kaydı olmadığı; (c) köprü ile ilişkili görünürlükten bize fayda, Armbet, Inc'in yokluğundan ağır basar; ve (d) bağlantının genel kaynak bilgileri bağlamında olması veya kurumun misyonunu ilerleten bir bülten veya benzer bir üründeki içerik içeriğiyle tutarlı olması.",
    "user.terms.2.text17": "Bu kuruluşlar, ana sayfamıza, yayınlara veya diğer Web sitesi bilgilerine bağlantı (A) hiçbir şekilde yanıltıcı olmadığı sürece bağlanabilir; (b) bağlantıyı yapan tarafın ve onun ürün veya hizmetlerinin yanlış bir şekilde sponsorluğunu, onayını veya onayını ima etmez; ve (c) bağlanan tarafın sitesi bağlamına uyar.",
    "user.terms.2.text18": "Yukarıdaki 2. paragrafta listelenen kuruluşlar arasındaysanız ve web sitemize bağlantı vermekle ilgileniyorsanız, adresine e-posta göndererek bizi bilgilendirmelisiniz.",
    "user.terms.2.text19": "Lütfen adınızı, kuruluş adınızı, iletişim bilgilerinizi (telefon numarası ve / veya e-posta adresi gibi) ve sitenizin URL'sini, Web sitemize bağlamak istediğiniz URL'lerin bir listesini, ve sitemizde bağlantı vermek istediğiniz URL'lerin bir listesi. Yanıt için 2-3 hafta bekleyin.",
    "user.terms.2.text20": "Onaylanmış kuruluşlar Web sitemize aşağıdaki gibi köprü oluşturabilir:",
    "user.terms.2.text21": "Kurumsal ismimizi kullanarak; veya",
    "user.terms.2.text22": "Birbirine bağlı tek tip kaynak bulucu (Web adresi) kullanılarak; veya",
    "user.terms.2.text23": "Web sitemizin veya buna bağlı olan materyalin başka bir tanımını kullanarak, bağlantı veren tarafın sitesindeki içerik bağlamı ve formatı içinde mantıklıdır.",
    "user.terms.2.text24": "Ticari marka lisans sözleşmesi bulunmaması için Armbet, Inc'in logosunun veya başka bir sanat eserinin kullanılmasına izin verilmeyecektir.",
    "user.terms.3.title": "Bağlantıların web sitemizden kaldırılması",
    "user.terms.3.text1": "Web sitemizde veya herhangi bir nedenden dolayı sakıncalı herhangi bir bağlantı bulursanız, bu konuda bizimle iletişime geçebilirsiniz. Bağlantıları kaldırma isteklerini dikkate alacağız, ancak bunu yapma veya doğrudan size yanıt verme yükümlülüğümüz olmayacaktır.",
    "user.terms.3.text2": "Bu web sitesindeki bilgilerin doğru olduğundan emin olmak için çaba göstersek de, eksiksizliğini veya doğruluğunu garanti etmiyoruz; web sitesinin kullanılabilir kalmasını veya web sitesindeki materyallerin güncel tutulmasını da taahhüt etmiyoruz.",
    "user.terms.4.title": "İçerik Sorumluluğu",
    "user.terms.4.text1": "Web sitenizde görünen herhangi bir içerik için hiçbir sorumluluğumuz veya yükümlülüğümüz yoktur. Web sitenizden kaynaklanan veya Web Sitenize dayalı tüm iddialara karşı bizi tazmin etmeyi ve savunmayı kabul edersiniz. Web sitenizdeki herhangi bir sayfada veya iftira, müstehcen veya suçlu olarak yorumlanabilecek veya herhangi bir ihlali veya başka bir ihlali ihlal eden veya başka bir şekilde ihlal eden içerik veya materyaller içeren hiçbir bağlamda hiçbir bağlantı görünmez üçüncü taraf hakları.",
    "user.terms.5.title": "feragat",
    "user.terms.5.text1": "Yürürlükteki yasaların izin verdiği azami ölçüde, web sitemiz ve bu web sitesinin kullanımı ile ilgili tüm beyanları, garantileri ve koşulları (sınırlama olmaksızın, tatmin edici kalite, amaca uygunluk ve / veya makul bakım ve beceri kullanımı). Bu feragatnamede yer alan hiçbir şey:",
    "user.terms.5.text2": "ihmalden kaynaklanan ölüm veya kişisel yaralanmalardan dolayı veya bizim sorumluluğunuzu sınırlamak veya hariç tutmak;",
    "user.terms.5.text3": "sahtekarlık veya hileli yanlış beyanlardan dolayı bizim veya sorumluluğunuzun sınırlandırılması veya hariç tutulması;",
    "user.terms.5.text4": "bizim veya herhangi bir yükümlülüğünüzü, yürürlükteki yasalara izin verilmeyen herhangi bir şekilde sınırlamak; veya",
    "user.terms.5.text5": "yürürlükteki yasalar kapsamına girmeyebilecek tüm yükümlülüklerimizi veya borçlarınızı hariç tutun.",
    "user.terms.5.text6": "Bu Bölümde ve bu feragatnamenin başka bir yerinde belirtilen sorumluluk sınırlamaları ve istisnaları: (a) bir önceki paragrafa tabidir; ve (b) feragatnamede veya bu feragatin konusuyla ilgili olarak doğan, sözleşmede ortaya çıkan yükümlülükler, haksız fiil (ihmal dahil) ve yasal verginin ihlali ile ilgili tüm yükümlülükleri yönetir.",
    "user.terms.5.text7": "Web sitesi ve web sitesindeki bilgi ve hizmetler ücretsiz olarak sağlandığı sürece, herhangi bir nitelikteki zarar veya ziyandan sorumlu olmayacağız."
}