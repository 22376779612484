import i18n from '../i18n';


var globals = {
  trans: i18n.t,
  admins: ["5ce93ff40b69011ea103dd16", "5ce97f7b0b69011ea103dd26", "5cead03fe068535ca71db55d"],
  apiString: "https://api.armbet.com/",
  //apiString: "http://armbet.5stardesigners.net:3020/",
  language: "en"
};

export default globals;

