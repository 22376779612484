import React from 'react'
import { useHistory } from 'react-router-dom'
import Logo from '../assets/images/logo.png';


const SuccessfullUnsubscribe = () => {
    const history = useHistory()
    return (


        <div className="col-center">
            <div style={{ maxWidth: "100%", margin: "auto", margin: '4rem' }}>
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "center", padding: '2rem'
                }}>
                    <img src={Logo} width={'35%'} height={'100%'} />
                    <br />

                    <p style={{ fontWeight: "bold", color: "#333333", textAlign: "center" }}>You have been unsubsribed</p>
                    <p style={{ textAlign: "center", marginTop: "1px" }}>If you do like to re-subscribe to this list, Use the link below</p>
                    <br />
                    <button onClick={() => window.location.replace('https://armbet.net/')} style={{
                        backgroundColor: "#e72b2c", color: "white",
                        padding: "1rem 5rem", borderRadius: "7px",
                        cursor: 'pointer'
                    }}>Go to Website</button>
                </div>
            </div>
        </div>

    )
}

export default SuccessfullUnsubscribe