import { createStore, applyMiddleware } from 'redux';
import reduxThunk from "redux-thunk";
import axiosMiddleware from 'redux-axios-middleware';
//import reducer from './reducer';
import axios from 'axios';

const client = axios.create({
  baseURL: 'api.armbet.com',
  responseType: 'json'
});

const INIT_STATE = {
  loading: {
      type:null
  },
  showMessage: false,
  showSideDialog: false
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
      case "LOADING":
          return { ...state, loading: action.payload };
      case "SHOW_MESSAGE":
          return { ...state, showMessage: action.payload };
      default:
          return state;
  }
}


const store = createStore(reducer, applyMiddleware(axiosMiddleware(client), reduxThunk));

export default store;