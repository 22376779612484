export default {
    "_colon_": ": ",
    "languages.english":"English",
    "languages.french":"French",
    "languages.german": "German",
    "languages.spanish": "Spanish",
    "languages.hindi": "Hindi",
    "languages.italian": "Italian",
    "languages.georgian": "Georgian",
    "languages.portuguese": "Portuguese",
    "languages.russian": "Russian",
    "languages.swedish": "Swedish",
    "languages.turkish": "Turkish",
    "languages.ukrainian": "Ukrainian",
    "logoAlt":"Armbet logo",
    "password": "Password",
    "email": "Email address",
    "changelanguage": "Change your language",
    "userterms": "User terms and conditions",
    "privacypolicy": "Privacy policy",
    "copyrightmessage" : "© Copyright 2020 Armbet. All rights reserved.",
    "backtohomepage": "Back to homepage",
    "message": "Message",
    "close": "Close",
    "notFound.title": "404",
    "notFound.text": "We're sorry but that page couldn't be found...",
    "errorhasoccured": "We're sorry but an error occured... Sorry for the inconvenience.",
    "networkerror": "We're sorry but we could not connect to the server...",
    "emailcouldntsend": "We're sorry but we could send the email. Please check your email address and try again.",
    "nothanks": "No thanks",

    "accountactivated": "Your account has been activated!",
    "accountactivatedtext": "You have created your account for the Armbet app that is coming soon. Remember your email and password and we will contact you when the app has been released. See you on the other side.",
    "accountalreadyactivated": "Your account has already been activated!",
    "linknotvalid": "The link has expired",
    "linknotvalidtext": "The link you entered is not valid, if you want to activate your account please request that a new email is sent to your indox.",

    "resetpassword": "Enter a new password",
    "resetpasswordtext": "Please enter a new passsword that contains at least 6 characters",
    "confirmpassword": "Confirm password",
    "resetpassworderror": "The link is not valid or has expired",
    "resetpassworderrortext": "Something is wrong with your password reset link. Either it has expired or it is simply not valid. Please request a new link on your Armbet app and we will send you a new email.",
    "resetpasswordsuccess": "Your new password has been saved",
    "resetpasswordsuccesstext": "You can now go back to your Armbet app and log in with your new password, see you there.",
    
}